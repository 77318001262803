import { Context, createContext, useEffect, useState } from "react";
import useSolanaContext from "hooks/useSolanaContext";
import { useAnchorWallet } from "@solana/wallet-adapter-react";
import getMintAddressesByOwner from "utils/solana/misc/getMintAddressesByOwner";
import getWhitelist from "utils/firebase/getWhitelist";
import setWhitelist from "utils/firebase/setWhitelist";

import MintAddresses from "utils/solana/misc/MintAddresses";

export type WhitelistContextData = {
  isWhitelisted: boolean;
  isChecking: boolean;
};

export const WhitelistContext: Context<WhitelistContextData> =
  createContext<WhitelistContextData>({
    isWhitelisted: false,
    isChecking: false,
  });

type ProviderProps = {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  children: any;
};

export function WhitelistContextProvider(props: ProviderProps): JSX.Element {
  const [isWhitelisted, setIsWhitelisted] = useState(false);
  const [isChecking, setIsChecking] = useState(false);
  const { connection } = useSolanaContext();

  const wallet = useAnchorWallet();

  useEffect(() => {
    (async () => {
      if (wallet != null) {
        setIsChecking(true);

        const whitelist = await getWhitelist(wallet.publicKey.toString());
        if (whitelist) {
          setIsChecking(false);
          setIsWhitelisted(true);
        }
        const mintAddressesByOwner = await getMintAddressesByOwner(
          connection,
          wallet.publicKey
        );
        const isWhitelist = MintAddresses.some((mintAddress) =>
          mintAddressesByOwner.some((address) => address === mintAddress)
        );

        if (isWhitelist) {
          setIsChecking(false);
          setIsWhitelisted(true);
          if (!whitelist) {
            await setWhitelist(wallet.publicKey.toString(), true);
          }
          console.log("Whitelisted!");
        } else {
          if (whitelist) {
            await setWhitelist(wallet.publicKey.toString(), false);
          }
          setIsChecking(false);
          setIsWhitelisted(false);
        }
      } else {
        setIsWhitelisted(false);
      }
    })();
  }, [wallet?.publicKey, connection]);

  return (
    <WhitelistContext.Provider
      // eslint-disable-next-line react/jsx-no-constructed-context-values
      value={{
        isWhitelisted,
        isChecking,
      }}
    >
      {props.children}
    </WhitelistContext.Provider>
  );
}
