import AmplitudeEvent from "types/enums/AmplitudeEvent";
import getAmplitude from "utils/amplitude/getAmplitude";
import { useEffect } from "react";

export default function useLogPageView(props?: { [key: string]: string }) {
  useEffect(() => {
    const amplitude = getAmplitude();
    amplitude.logEvent(AmplitudeEvent.PageView, {
      origin: window.self.origin,
      pathname: window.location.pathname,
      ...props,
    });
  }, [props]);
}
