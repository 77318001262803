import { get } from "idb-keyval";
import LocalStorageKey from "types/enums/LocalStorageKey";
import prefixKeyWithProjectId from "utils/local-storage/prefixKeyWithProjectId";

export default async function getLocalStorage(
  key: LocalStorageKey,
  projectId?: string
): Promise<any> {
  if (
    [LocalStorageKey.AllProjectIds, LocalStorageKey.ProjectId].includes(key)
  ) {
    return get(key);
  }

  const prefixedKey = await prefixKeyWithProjectId(key, projectId);
  return get(prefixedKey);
}
