import styles from "css/pages/generate/basic-info/BasicInfoPage.module.css";
import ContainerOuter from "components/common/ContainerOuter";
import useGenerateConfigContext from "hooks/useGenerateConfigContext";
import TextInput from "components/input/TextInput";
import isNumber from "utils/isNumber";
import numberWithCommas from "utils/numberWithCommas";
import numberRemoveCommas from "utils/numberRemoveCommas";
import InputWithQuestionAndLabel from "components/input/InputWithQuestionAndLabel";
import GenerateStepsContainer from "components/common/GenerateStepsContainer";
import BigToggleButton from "components/buttons/BigToggleButton";
import Blockchain from "types/enums/Blockchain";
import NextButton from "components/buttons/NextButton";
import ButtonText from "components/text/ButtonText";
import ColorClass from "types/enums/ColorClass";
import TextButton from "components/buttons/TextButton";
import TextButtonTheme from "types/enums/TextButtonTheme";
import FontClass from "types/enums/FontClass";
import JSZip from "jszip";
import invariant from "tiny-invariant";
import { saveAs } from "file-saver";
import getFileExtension from "utils/getFileExtension";
import { useState } from "react";
import LoadingSpinner from "components/loading/LoadingSpinner";
import ColorValue from "types/enums/ColorValue";
import ElementMobileNotSupported from "components/hoc/ElementMobileNotSupported";
import ImageWebp from "components/images/ImageWebp";
import GateModal from "components/modal/GateModal";
import Body1 from "components/text/Body1";
import useLogPageView from "hooks/useLogPageView";
import useWhitelistContext from "hooks/useWhitelistContext";

const ID = "input_id";

function BlockchainInput(): JSX.Element {
  const { blockchain, setBlockchain } = useGenerateConfigContext();

  return (
    <InputWithQuestionAndLabel
      label="We currently support metadata generation for Ethereum and Solana NFTs. If you plan to use a different blockchain, you can still use this tool to generate your images but the metadata will not be usable."
      question="Which blockchain are you using?"
    >
      <div className={styles.bigToggleBlockchain}>
        <BigToggleButton
          labels={["Solana", "Ethereum"]}
          onToggle={(val) => {
            if (val === 0) {
              setBlockchain(Blockchain.Solana);
            } else {
              setBlockchain(Blockchain.Ethereum);
            }
          }}
          position={blockchain === Blockchain.Solana ? 0 : 1}
        />
      </div>
    </InputWithQuestionAndLabel>
  );
}

function FolderInput(): JSX.Element {
  const { filesMap, folderName, updateProjectFiles } =
    useGenerateConfigContext();
  const [isDownloading, setIsDownloading] = useState(false);
  const [isModalShown, setIsModalShown] = useState(false);
  const { isWhitelisted } = useWhitelistContext();

  return (
    <>
      <GateModal isShown={isModalShown} onHide={() => setIsModalShown(false)} />
      <InputWithQuestionAndLabel
        label={
          <>
            If you change the art layer files, you will have to re-upload
            (&ldquo;Change Folder&rdquo;) your main folder for the changes to
            take place.
          </>
        }
        question="Which folder should be used for this project?"
      >
        <div className={styles.folderInput}>
          <input
            className={styles.changeFolderInput}
            id={ID}
            onChange={async (e) => {
              if (e.target.files == null) {
                return;
              }

              if (
                [...e.target.files].find((file) => file.type.includes("gif")) !=
                  null &&
                !isWhitelisted
              ) {
                setIsModalShown(true);
                // Allow upload again
                e.target.value = "";
                return;
              }

              await updateProjectFiles(e.target.files);
            }}
            type="file"
            // Directory stuff
            // @ts-ignore
            directory=""
            multiple
            webkitdirectory=""
          />
          <ImageWebp className={styles.folderImage} src="/images/folder.svg" />
          <div className={styles.folderInputText}>
            {folderName != null && (
              <ButtonText colorClass={ColorClass.Navy} textAlign="center">
                {folderName}
              </ButtonText>
            )}
            <TextButton
              buttonTheme={TextButtonTheme.Purple}
              fontClass={FontClass.ButtonText}
              htmlFor={ID}
              type="label"
            >
              Change Folder
            </TextButton>
            <TextButton
              buttonTheme={TextButtonTheme.Purple}
              fontClass={FontClass.ButtonText}
              onClick={async () => {
                if (isDownloading) {
                  return;
                }

                setIsDownloading(true);

                invariant(filesMap != null);
                const zip = new JSZip();

                filesMap.forEach((value, key) => {
                  const folder = zip.folder(key);
                  value.forEach((file) => {
                    folder!.file(
                      `${file.name}.${getFileExtension(file)}`,
                      file
                    );
                  });
                });

                const generated = await zip.generateAsync({ type: "blob" });
                saveAs(generated, "files.zip");

                setIsDownloading(false);
              }}
            >
              {isDownloading ? (
                <LoadingSpinner
                  colorValue={ColorValue.Purple}
                  height={26}
                  width={26}
                />
              ) : (
                "Download Files"
              )}
            </TextButton>
          </div>
        </div>
      </InputWithQuestionAndLabel>
    </>
  );
}

// eslint-disable-next-line @typescript-eslint/no-unused-vars
function GenerateMetadataInput(): JSX.Element {
  const { generateMetadata, setGenerateMetadata } = useGenerateConfigContext();

  return (
    <InputWithQuestionAndLabel
      label="Metadata is used to identify traits."
      question="Do you want to generate metadata?"
    >
      <div className={styles.bigToggleMetadata}>
        <BigToggleButton
          labels={["Yes", "No"]}
          onToggle={(val) => {
            if (val === 0) {
              setGenerateMetadata(true);
            } else {
              setGenerateMetadata(false);
            }
          }}
          position={generateMetadata ? 0 : 1}
        />
      </div>
    </InputWithQuestionAndLabel>
  );
}

function NumImagesInput(): JSX.Element {
  const { numImages, setNumImages } = useGenerateConfigContext();
  const [isModalShown, setIsModalShown] = useState(true);
  const [isTouched, setIsTouched] = useState(false);
  const { isWhitelisted } = useWhitelistContext();

  return (
    <>
      <GateModal
        isShown={
          isTouched && isModalShown && numImages > 1000 && !isWhitelisted
        }
        onHide={() => setIsModalShown(false)}
      />
      <InputWithQuestionAndLabel question="How many images do you want to generate in total?">
        <TextInput
          className={styles.numImagesInput}
          hasError={!isWhitelisted && numImages > 1000}
          onChange={(val) => {
            const valCommasRemoved = numberRemoveCommas(val);
            if (!isNumber(valCommasRemoved)) {
              return;
            }
            setIsTouched(true);
            setNumImages(Number(valCommasRemoved));
          }}
          value={numberWithCommas(numImages)}
        />
      </InputWithQuestionAndLabel>
    </>
  );
}

function ProjectNameInput(): JSX.Element {
  const { projectName, setProjectName } = useGenerateConfigContext();

  return (
    <InputWithQuestionAndLabel question="What’s the name of this project?">
      <TextInput
        className={styles.projectNameInput}
        onChange={setProjectName}
        value={projectName ?? ""}
      />
    </InputWithQuestionAndLabel>
  );
}

export default function BasicInfoPage(): JSX.Element {
  useLogPageView();
  const { isWhitelisted } = useWhitelistContext();
  const { numImages } = useGenerateConfigContext();
  const hasError = !isWhitelisted && numImages > 1000;

  return (
    <ElementMobileNotSupported>
      <ContainerOuter>
        <GenerateStepsContainer>
          <FolderInput />
          <ProjectNameInput />
          <NumImagesInput />
          {/* <GenerateMetadataInput /> */}
          <BlockchainInput />
          <NextButton disabled={hasError} href="/generate/metadata" />
          {hasError && (
            <Body1
              className={styles.error}
              colorClass={ColorClass.Error}
              textAlign="center"
            >
              Please make sure all inputs are valid before continuing.
            </Body1>
          )}
        </GenerateStepsContainer>
      </ContainerOuter>
    </ElementMobileNotSupported>
  );
}
