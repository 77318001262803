import GenerateStep from "types/enums/GenerateStep";

export default function useGenerateStep(): GenerateStep {
  switch (window.location.pathname) {
    case "/generate/basic-info":
      return GenerateStep.BasicInfo;
    case "/generate/metadata":
      return GenerateStep.Metadata;
    case "/generate/layer-ordering":
      return GenerateStep.LayerOrdering;
    case "/generate/trait-allocation":
      return GenerateStep.TraitAllocation;
    case "/generate/generation":
      return GenerateStep.Generation;
    default:
      return GenerateStep.BasicInfo;
  }
}
