import ContainerOuter from "components/common/ContainerOuter";
import GenerateStepsContainer from "components/common/GenerateStepsContainer";
import styles from "css/pages/generate/metadata/MetadataPage.module.css";
import useGenerateConfigContext from "hooks/useGenerateConfigContext";
import InputWithQuestionAndLabel from "components/input/InputWithQuestionAndLabel";
import TextInput from "components/input/TextInput";
import TextArea from "components/input/TextArea";
import NextButton from "components/buttons/NextButton";
import Blockchain from "types/enums/Blockchain";
import { Maybe } from "types/UtilityTypes";
import isNumber from "utils/isNumber";
import TextButton from "components/buttons/TextButton";
import TextButtonTheme from "types/enums/TextButtonTheme";
import FontClass from "types/enums/FontClass";
import CloseButton from "components/buttons/CloseButton";
import ColorValue from "types/enums/ColorValue";
import Body2 from "components/text/Body2";
import ColorClass from "types/enums/ColorClass";
import sumArray from "utils/sumArray";
import NextAndBackButton from "components/buttons/NextAndBackButton";
import BackButton from "components/buttons/BackButton";
import { useState } from "react";
import Body1 from "components/text/Body1";
import ElementMobileNotSupported from "components/hoc/ElementMobileNotSupported";
import useLogPageView from "hooks/useLogPageView";

type ClickedProps = { clicked: boolean };

function MetaDescriptionInput(): JSX.Element {
  const { metaDescription, setMetaDescription } = useGenerateConfigContext();

  return (
    <InputWithQuestionAndLabel
      label="In the metadata, the description will be the same for each image."
      question="What is the description of your collection?"
    >
      <TextArea
        className={styles.metaDescriptionInput}
        maxLength={1000}
        maxLengthIndicator={false}
        onChange={setMetaDescription}
        placeholder="A collection of 2222 red rabbits."
        rows={4}
        value={metaDescription ?? ""}
      />
    </InputWithQuestionAndLabel>
  );
}

function MetaNameInput({ clicked }: ClickedProps): JSX.Element {
  const { metaName, setMetaName } = useGenerateConfigContext();

  return (
    <InputWithQuestionAndLabel
      label="In the metadata, each image will be called NAME #ID."
      question="How should each image be named in the metadata?"
    >
      <TextInput
        className={styles.metaNameInput}
        hasError={clicked && (metaName == null || metaName.length === 0)}
        onChange={setMetaName}
        placeholder="Rabbit"
        value={metaName ?? ""}
      />
    </InputWithQuestionAndLabel>
  );
}

///
/// Solana stuff
///

function MetaCollectionFamilyInput({ clicked }: ClickedProps): JSX.Element {
  const { metaCollectionFamily, setMetaCollectionFamily } =
    useGenerateConfigContext();

  return (
    <InputWithQuestionAndLabel
      label={
        <>
          See{" "}
          <a href="https://docs.metaplex.com/token-metadata/specification">
            here
          </a>{" "}
          for an example.
        </>
      }
      question="What is the family of this collection?"
    >
      <TextInput
        className={styles.metaNameInput}
        hasError={
          clicked &&
          (metaCollectionFamily == null || metaCollectionFamily.length === 0)
        }
        onChange={setMetaCollectionFamily}
        placeholder="Rabbits"
        value={metaCollectionFamily ?? ""}
      />
    </InputWithQuestionAndLabel>
  );
}

function MetaCollectionNameInput({ clicked }: ClickedProps): JSX.Element {
  const { metaCollectionName, setMetaCollectionName } =
    useGenerateConfigContext();

  return (
    <InputWithQuestionAndLabel question="What is the name of this collection?">
      <TextInput
        className={styles.metaNameInput}
        hasError={
          clicked &&
          (metaCollectionName == null || metaCollectionName.length === 0)
        }
        onChange={setMetaCollectionName}
        placeholder="Red Rabbits"
        value={metaCollectionName ?? ""}
      />
    </InputWithQuestionAndLabel>
  );
}

function MetaExternalUrl(): JSX.Element {
  const { metaExternalUrl, setMetaExternalUrl } = useGenerateConfigContext();

  return (
    <InputWithQuestionAndLabel question="What is the external URL for this collection?">
      <TextInput
        className={styles.metaNameInput}
        onChange={setMetaExternalUrl}
        placeholder="https://mycoverse.xyz/"
        value={metaExternalUrl ?? ""}
      />
    </InputWithQuestionAndLabel>
  );
}

function MetaRoyaltiesRow({
  clicked,
  index,
}: {
  clicked: boolean;
  index: number;
}): JSX.Element {
  const { metaRoyalties, dispatchRoyalties } = useGenerateConfigContext();
  const sum = sumArray(metaRoyalties.map(({ share }) => share));

  return (
    <div className={styles.royaltiesRow}>
      <TextInput
        className={styles.walletAddress}
        hasError={clicked && metaRoyalties[index].address.length === 0}
        onChange={(val) =>
          dispatchRoyalties({ type: "update_address", address: val, index })
        }
        value={metaRoyalties[index].address}
      />
      <TextInput
        className={styles.walletPercent}
        hasError={sum > 100 || (clicked && sum !== 100)}
        onChange={(val) => {
          if (isNumber(val) && Number(val) > 0 && Number(val) <= 100) {
            dispatchRoyalties({
              type: "update_share",
              share: Number(val),
              index,
            });
          } else if (val.length === 0) {
            dispatchRoyalties({
              type: "update_share",
              share: 0,
              index,
            });
          }
        }}
        value={metaRoyalties[index].share.toString()}
      />
      <CloseButton
        colorValue={ColorValue.Navy}
        isShown={index > 0}
        onClick={() => dispatchRoyalties({ type: "remove_row", index })}
      />
    </div>
  );
}

function MetaRoyaltiesInput({ clicked }: ClickedProps): JSX.Element {
  const { metaRoyalties, dispatchRoyalties } = useGenerateConfigContext();

  return (
    <InputWithQuestionAndLabel
      label="For secondary sales of these NFTs, designate the percentage of royalties going to each wallet address (usually corresponds to each creator). Percentages should add up to 100."
      question="How should the royalties be split?"
    >
      <div className={styles.royaltiesLabels}>
        <Body2
          className={styles.royaltiesLabelsLeft}
          colorClass={ColorClass.Navy}
        >
          Wallet Address
        </Body2>
        <Body2 colorClass={ColorClass.Navy}>Percentage</Body2>
      </div>
      <div className={styles.royalties}>
        {metaRoyalties.map((_royalties, index) => (
          <MetaRoyaltiesRow clicked={clicked} index={index} />
        ))}
        <TextButton
          buttonTheme={TextButtonTheme.Navy}
          fontClass={FontClass.Body1Bold}
          onClick={() => dispatchRoyalties({ type: "add_row" })}
        >
          + Add another wallet address
        </TextButton>
      </div>
    </InputWithQuestionAndLabel>
  );
}

function MetaSellerFeeBasisPointsInput(): JSX.Element {
  const { metaSellerFeeBasisPoints, setMetaSellerFeeBasisPoints } =
    useGenerateConfigContext();

  return (
    <InputWithQuestionAndLabel
      label="500 basis points = 5%. The royalties get distributed to each creator, as determined below."
      question="How much royalties should creators receive for secondary sales, in basis points?"
    >
      <TextInput
        className={styles.metaNameInput}
        onChange={(val) => {
          if (!isNumber(val)) {
            return;
          }
          setMetaSellerFeeBasisPoints(Number(val));
        }}
        value={metaSellerFeeBasisPoints.toString()}
      />
    </InputWithQuestionAndLabel>
  );
}

function MetaSymbol(): JSX.Element {
  const { metaSymbol, setMetaSymbol } = useGenerateConfigContext();

  return (
    <InputWithQuestionAndLabel question="What is the symbol for this collection?">
      <TextInput
        className={styles.metaNameInput}
        onChange={setMetaSymbol}
        placeholder="RAB"
        value={metaSymbol ?? ""}
      />
    </InputWithQuestionAndLabel>
  );
}

function SolanaMetaInputs({ clicked }: ClickedProps): Maybe<JSX.Element> {
  const { blockchain } = useGenerateConfigContext();

  if (blockchain !== Blockchain.Solana) {
    return null;
  }
  return (
    <>
      <MetaCollectionNameInput clicked={clicked} />
      <MetaCollectionFamilyInput clicked={clicked} />
      <MetaSellerFeeBasisPointsInput />
      <MetaSymbol />
      <MetaRoyaltiesInput clicked={clicked} />
      <MetaExternalUrl />
    </>
  );
}

export default function MetadataPage(): JSX.Element {
  useLogPageView();
  const [clicked, setClicked] = useState(false);
  const {
    blockchain,
    metaCollectionFamily,
    metaCollectionName,
    metaName,
    metaRoyalties,
  } = useGenerateConfigContext();
  const sum = sumArray(metaRoyalties.map(({ share }) => share));

  const solanaError =
    blockchain === Blockchain.Solana &&
    (sum !== 100 ||
      metaRoyalties.find(({ address }) => address.length === 0) != null ||
      metaCollectionFamily == null ||
      metaCollectionFamily.length === 0 ||
      metaCollectionName == null ||
      metaCollectionName.length === 0);
  const hasError = metaName == null || metaName.length === 0 || solanaError;

  return (
    <ElementMobileNotSupported>
      <ContainerOuter>
        <GenerateStepsContainer>
          <MetaNameInput clicked={clicked} />
          <MetaDescriptionInput />
          <SolanaMetaInputs clicked={clicked} />
          <NextAndBackButton>
            <BackButton href="/generate/basic-info" />
            <NextButton
              href="/generate/layer-ordering"
              onClick={() => {
                setClicked(true);
                return !hasError;
              }}
            />
          </NextAndBackButton>
          {hasError && clicked && (
            <Body1
              className={styles.error}
              colorClass={ColorClass.Error}
              textAlign="center"
            >
              Please make sure all inputs are valid before continuing.
            </Body1>
          )}
        </GenerateStepsContainer>
      </ContainerOuter>
    </ElementMobileNotSupported>
  );
}
