import GenerateStep from "types/enums/GenerateStep";

export default function getGenerateStepHref(
  generateStep: GenerateStep
): string {
  switch (generateStep) {
    case GenerateStep.BasicInfo:
      return "/generate/basic-info";
    case GenerateStep.Metadata:
      return "/generate/metadata";
    case GenerateStep.LayerOrdering:
      return "/generate/layer-ordering";
    case GenerateStep.TraitAllocation:
      return "/generate/trait-allocation";
    case GenerateStep.Generation:
      return "/generate/generation";
    default:
      throw new Error("should not reach");
  }
}
