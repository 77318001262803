import ButtonWithText from "components/buttons/ButtonWithText";
import usePrefetch from "hooks/usePrefetch";
import ButtonTheme from "types/enums/ButtonTheme";
import { useNavigate } from "react-router-dom";

type Props = {
  className?: string;
  disabled?: boolean;
  href: string;
  onClick?: () => boolean;
};

export default function NextButton({
  className,
  disabled = false,
  href,
  onClick,
}: Props): JSX.Element {
  usePrefetch(href);
  const navigate = useNavigate();

  if (onClick != null) {
    return (
      <ButtonWithText
        buttonTheme={ButtonTheme.Purple}
        className={className}
        disabled={disabled}
        href={href}
        onClick={() => {
          const shouldNav = onClick();
          if (shouldNav) {
            navigate(href);
          }
        }}
        width="auto"
      >
        Next
      </ButtonWithText>
    );
  }

  return (
    <ButtonWithText
      buttonTheme={ButtonTheme.Purple}
      className={className}
      disabled={disabled}
      href={href}
      type="link_internal"
      width="auto"
    >
      Next
    </ButtonWithText>
  );
}
