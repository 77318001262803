import styles from "css/input/InputWithLeftLabel.module.css";
import ButtonText from "components/text/ButtonText";
import ColorClass from "types/enums/ColorClass";

type Props = {
  children: any;
  label: string;
};

export default function InputWithLeftLabel({
  children,
  label,
}: Props): JSX.Element {
  return (
    <>
      <ButtonText className={styles.label} colorClass={ColorClass.Navy}>
        {label}
      </ButtonText>
      <div className={styles.input}>{children}</div>
    </>
  );
}
