import LocalStorageKey from "types/enums/LocalStorageKey";
import getLocalStorage from "utils/local-storage/getLocalStorage";
import invariant from "tiny-invariant";

export default async function prefixKeyWithProjectId(
  key: string,
  projectId?: string
): Promise<string> {
  // eslint-disable-next-line no-param-reassign
  projectId =
    projectId == null
      ? await getLocalStorage(LocalStorageKey.ProjectId)
      : projectId;
  invariant(projectId != null, "Must be true");
  return `${projectId}-${key}`;
}
