import styles from "css/pages/generate/generation/GenerationPage.module.css";
import JSZip from "jszip";
import { useEffect, useState } from "react";
import ButtonWithText from "components/buttons/ButtonWithText";
import PlainButton from "components/buttons/PlainButton";
import ContainerOuter from "components/common/ContainerOuter";
import GenerateStepsContainer from "components/common/GenerateStepsContainer";
import LayersIcon from "components/icons/LayersIcon";
import SearchIcon from "components/icons/SearchIcon";
import ImageModal from "components/modal/ImageModal";
import MetadataModal from "components/modal/MetadataModal";
import QuestionText from "components/text/QuestionText";
import useGenerateConfigContext from "hooks/useGenerateConfigContext";
import ButtonTheme from "types/enums/ButtonTheme";
import ColorClass from "types/enums/ColorClass";
import ColorValue from "types/enums/ColorValue";
import FontClass from "types/enums/FontClass";
import GlobalClass from "types/enums/GlobalClass";
import Metadata from "types/Metadata";
import isTraitWeightsValid from "utils/isTraitWeightsValid";
import joinClasses from "utils/joinClasses";
import numberWithCommas from "utils/numberWithCommas";
import { saveAs } from "file-saver";
import getDataFromDataUri from "utils/getDataFromDataUri";
import isPixelArt from "utils/isPixelArt";
import Body2 from "components/text/Body2";
import isDataUriGif from "utils/isDataUriGif";
import JoinDiscordModal from "components/modal/JoinDiscordModal";
import ElementMobileNotSupported from "components/hoc/ElementMobileNotSupported";
import useLogPageView from "hooks/useLogPageView";
import logIfNotProd from "utils/logIfNotProd";
import useWhitelistContext from "hooks/useWhitelistContext";
import Blockchain from "types/enums/Blockchain";

export const DL_BATCH_SIZE = 500;
export const BATCH_SIZE = 1000;
const PAGE_SIZE = 100;

function InvalidConfig(): JSX.Element {
  return (
    <QuestionText
      className={styles.description}
      colorClass={ColorClass.White}
      textAlign="center"
    >
      Please fill out all required info before trying to generate images.
    </QuestionText>
  );
}

function NotWhitelisted(): JSX.Element {
  return (
    <QuestionText
      className={styles.description}
      colorClass={ColorClass.White}
      textAlign="center"
    >
      You must own a Mycoverse NFT to use premium features (generate &gt;1000
      images, GIF support, shuffle tool). If you own one, connect your wallet!
    </QuestionText>
  );
}

function isNumImagesAndGifAllowed(
  isWhitelisted: boolean,
  numImages: number,
  files?: File[]
) {
  return (
    !isWhitelisted &&
    (numImages > 1000 || files?.some((file) => file.type === "image/gif"))
  );
}

function GridItem({
  metadata,
  src,
}: {
  metadata: Metadata;
  src: string;
}): JSX.Element {
  const [imageModalShown, setIsImageModelShown] = useState(false);
  const [metadataModalShown, setIsMetadataModelShown] = useState(false);
  const { fileDimensions } = useGenerateConfigContext();

  return (
    <>
      <ImageModal
        isShown={imageModalShown}
        onHide={() => setIsImageModelShown(false)}
        src={src}
      />
      <MetadataModal
        isShown={metadataModalShown}
        metadata={metadata}
        onHide={() => setIsMetadataModelShown(false)}
      />
      <div className={joinClasses(styles.imageContainer, GlobalClass.HideText)}>
        <img
          className={joinClasses(
            styles.image,
            isPixelArt(fileDimensions) ? GlobalClass.PixelArtImage : null
          )}
          src={src}
        />
        <div className={styles.buttons}>
          <PlainButton
            className={joinClasses(styles.button, FontClass.Body2)}
            onClick={() => setIsImageModelShown(true)}
          >
            <SearchIcon colorValue={ColorValue.White} />
            Expand
          </PlainButton>
          <PlainButton
            className={joinClasses(styles.button, FontClass.Body2)}
            onClick={() => setIsMetadataModelShown(true)}
          >
            <LayersIcon colorValue={ColorValue.White} />
            Metadata
          </PlainButton>
        </div>
      </div>
    </>
  );
}

function ValidConfig(): JSX.Element {
  const {
    blockchain,
    files,
    generateImages,
    generatedImagesAndMetadata,
    isDoneGenerating,
    numImages,
    seenLayers,
    setIsDoneGenerating,
  } = useGenerateConfigContext();
  const { isWhitelisted } = useWhitelistContext();
  useEffect(() => {
    async function run() {
      setIsDoneGenerating(false);
      // Pass in empty array to reset after client-side nav.
      await generateImages([]);
    }

    if (
      !isNumImagesAndGifAllowed(isWhitelisted, numImages, files as File[]) ||
      isWhitelisted
    ) {
      run();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [numImages]);
  const [shownLimit, setShownLimit] = useState(PAGE_SIZE);
  const [isDownloading, setIsDownloading] = useState(false);
  const [isModalShown, setIsModalShown] = useState(false);

  const onDownload = async () => {
    setIsDownloading(true);

    const blockchainInc = blockchain === Blockchain.Ethereum ? 1 : 0;
    let zip = new JSZip();
    let imagesFolder = zip.folder("images");
    let metadataFolder = zip.folder("metadata");
    let firstIndex = 0;
    let inc = 0;

    if (seenLayers.length === numImages) {
      if (numImages > BATCH_SIZE) {
        // Two scenarios: one where numImages % BATCH_SIZE is zero and one where it is nonzero.
        //
        // First scenario example: if BATCH_SIZE is 10 and we have seen all 45 images so far, inc is 40.
        //
        // Second scenario example: if BATCH_SIZE is 10 and we have seen all 50 images so far, inc is also 40.
        const numLeft =
          numImages - Math.floor(numImages / BATCH_SIZE) * BATCH_SIZE;
        inc = numLeft > 0 ? numImages - numLeft : numImages - BATCH_SIZE;
      } else {
        inc = 0;
      }
    } else if (seenLayers.length >= BATCH_SIZE) {
      // E.g. if BATCH_SIZE is 10 and we have seen 40 images so far, inc is 30.
      inc = seenLayers.length - BATCH_SIZE;
    }

    for (const [index, entry] of generatedImagesAndMetadata.entries()) {
      const format = isDataUriGif(entry.dataUri) ? "gif" : "png";
      imagesFolder!.file(
        `${index + inc + blockchainInc}.${format}`,
        getDataFromDataUri(entry.dataUri),
        {
          base64: true,
        }
      );
      metadataFolder!.file(
        `${index + inc + blockchainInc}.json`,
        JSON.stringify(entry.metadata, null, 2)
      );

      if (index !== 0 && index % DL_BATCH_SIZE === 0) {
        // eslint-disable-next-line no-await-in-loop
        const generated = await zip.generateAsync({ type: "blob" });
        saveAs(
          generated,
          `nfts${firstIndex + inc + blockchainInc}-${
            index + inc + blockchainInc
          }.zip`
        );
        firstIndex = index + 1;

        zip = new JSZip();
        imagesFolder = zip.folder("images");
        metadataFolder = zip.folder("metadata");
      }
    }

    if (firstIndex !== generatedImagesAndMetadata.length) {
      const generated = await zip.generateAsync({ type: "blob" });
      saveAs(
        generated,
        `nfts${firstIndex + inc + blockchainInc}-${
          generatedImagesAndMetadata.length - 1 + inc + blockchainInc
        }.zip`
      );
      setIsDownloading(false);
    }
  };

  let status = "";
  if (isDoneGenerating) {
    if (seenLayers.length < numImages) {
      status = `Done! Since duplicates were removed, less than ${numImages} images were generated`;
    } else {
      status = "Done!";
    }
  } else {
    status = "Generating images...";
  }

  return (
    <>
      <JoinDiscordModal
        isLoading={isDownloading}
        isShown={isModalShown}
        onClick={onDownload}
        onHide={() => setIsModalShown(false)}
      />
      <div className={styles.container}>
        <QuestionText
          className={styles.title}
          colorClass={ColorClass.White}
          textAlign="center"
        >
          {status}
        </QuestionText>
        <QuestionText
          className={styles.progress}
          colorClass={ColorClass.White}
          textAlign="center"
        >
          {numberWithCommas(seenLayers.length)} / {numberWithCommas(numImages)}
        </QuestionText>
        <Body2
          className={styles.note}
          colorClass={ColorClass.White}
          textAlign="center"
        >
          Note: duplicates are removed automatically.
          <br />
          Note: please refresh to re-generate.
        </Body2>
        <ButtonWithText
          buttonTheme={ButtonTheme.Purple}
          className={styles.downloadButton}
          disabled={
            seenLayers.length === 0 ||
            (seenLayers.length % BATCH_SIZE !== 0 && !isDoneGenerating)
          }
          onClick={async () => {
            setIsModalShown(true);
          }}
        >
          Download images &amp; metadata
        </ButtonWithText>
        <ButtonWithText
          buttonTheme={ButtonTheme.Purple}
          className={styles.downloadButton}
          disabled={
            seenLayers.length === 0 ||
            seenLayers.length % BATCH_SIZE !== 0 ||
            isDoneGenerating
          }
          onClick={async () => {
            await generateImages(seenLayers);
          }}
        >
          Generate next batch
        </ButtonWithText>
        <div className={styles.grid}>
          {generatedImagesAndMetadata
            .slice(0, shownLimit)
            .map(({ dataUri, metadata }, index) => (
              <GridItem
                // eslint-disable-next-line react/no-array-index-key
                key={index}
                metadata={metadata}
                src={dataUri}
              />
            ))}
        </div>
        {generatedImagesAndMetadata.length >= shownLimit && (
          <ButtonWithText
            buttonTheme={ButtonTheme.Purple}
            className={styles.loadMoreButton}
            onClick={() => setShownLimit((curr) => curr + PAGE_SIZE)}
          >
            View More
          </ButtonWithText>
        )}
      </div>
    </>
  );
}

export default function GenerationPage(): JSX.Element {
  useLogPageView();
  const {
    files,
    filesMap,
    filesMapDependent,
    numImages,
    traitNamesOrdered,
    traitWeights,
  } = useGenerateConfigContext();
  const { isWhitelisted } = useWhitelistContext();
  let body = <ValidConfig />;

  const isInvalid =
    filesMap == null ||
    filesMapDependent == null ||
    traitNamesOrdered == null ||
    !isTraitWeightsValid(traitWeights, numImages, traitNamesOrdered.length);

  if (isNumImagesAndGifAllowed(isWhitelisted, numImages, files as File[])) {
    body = <NotWhitelisted />;
  }

  if (isInvalid) {
    logIfNotProd(
      "invalid",
      filesMap,
      filesMapDependent,
      traitNamesOrdered,
      traitNamesOrdered != null
        ? isTraitWeightsValid(traitWeights, numImages, traitNamesOrdered.length)
        : "traitNamesOrdered is null"
    );
    body = <InvalidConfig />;
  }

  return (
    <ElementMobileNotSupported>
      <ContainerOuter>
        <GenerateStepsContainer>{body}</GenerateStepsContainer>
      </ContainerOuter>
    </ElementMobileNotSupported>
  );
}
