import { TraitWeights } from "context/GenerateConfigContext";
import { Maybe } from "types/UtilityTypes";

/**
 * Takes in a trait value like "Blue Hair" and returns "Blue Hair.png" if that file exists.
 */
export default function addTraitValueExtension(
  traitWeights: TraitWeights,
  traitName: string,
  traitValue: string
): Maybe<string> {
  const map = traitWeights.get(traitName);
  if (map == null) {
    return null;
  }

  const values = [...map.keys()];
  return values.find((value) => value.startsWith(`${traitValue}.`)) ?? null;
}
