import { Route, Routes as RoutesImport } from "react-router-dom";
import LandingPage from "components/pages/landing/LandingPage";
import HowItWorksPage from "components/pages/how-it-works/HowItWorksPage";
import HowItWorksPage1 from "components/pages/how-it-works/HowItWorksPage1";
import HowItWorksPage2 from "components/pages/how-it-works/HowItWorksPage2";
import HowItWorksPage3 from "components/pages/how-it-works/HowItWorksPage3";
import GeneratePage from "components/pages/generate/GeneratePage";
import BasicInfoPage from "components/pages/generate/basic-info/BasicInfoPage";
import GenerationPage from "components/pages/generate/generation/GenerationPage";
import LayerOrderingPage from "components/pages/generate/layer-ordering/LayerOrderingPage";
import MetadataPage from "components/pages/generate/metadata/MetadataPage";
import TraitAllocationPage from "components/pages/generate/trait-allocation/TraitAllocationPage";
import ShufflePage from "components/pages/shuffle/ShufflePage";

// TODO: figure out how to set page titles
export default function Routes(): JSX.Element {
  return (
    <RoutesImport>
      <Route path="/" element={<LandingPage />} />

      <Route path="/generate" element={<GeneratePage />} />
      <Route path="/generate/basic-info" element={<BasicInfoPage />} />
      <Route path="/generate/generation" element={<GenerationPage />} />
      <Route path="/generate/layer-ordering" element={<LayerOrderingPage />} />
      <Route path="/generate/metadata" element={<MetadataPage />} />
      <Route
        path="/generate/trait-allocation"
        element={<TraitAllocationPage />}
      />

      <Route path="/how-it-works" element={<HowItWorksPage />} />
      <Route path="/how-it-works/1" element={<HowItWorksPage1 />} />
      <Route path="/how-it-works/2" element={<HowItWorksPage2 />} />
      <Route path="/how-it-works/3" element={<HowItWorksPage3 />} />

      <Route path="/shuffle" element={<ShufflePage />} />
    </RoutesImport>
  );
}
