import GlobalClass from "types/enums/GlobalClass";
import joinClasses from "utils/joinClasses";
import styles from "css/common/ContainerOuter.module.css";
import Header from "components/header/Header";
import FooterShort from "components/footer/FooterShort";

type Props = {
  children: any;
  showFooter?: boolean;
};

export default function ContainerOuter({
  children,
  showFooter = true,
}: Props): JSX.Element {
  return (
    <div className={joinClasses(GlobalClass.GradientBg, styles.containerOuter)}>
      <div>
        <Header />
        {children}
      </div>
      {showFooter && <FooterShort />}
    </div>
  );
}
