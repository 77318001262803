import Body1Bold from "components/text/Body1Bold";
import ColorClass from "types/enums/ColorClass";
import ResponsiveContainer from "components/ResponsiveContainer";
import styles from "css/pages/landing/LandingBanner.module.css";
import DiscordIcon24 from "components/icons/DiscordIcon24";
import ColorValue from "types/enums/ColorValue";

export default function LandingBanner(): JSX.Element {
  return (
    // For some reason, margin 0 is needed for mobile browsers (otherwise there's a tiny top and bottom margin)
    <a
      href="https://discord.gg/hEJhj4GdSa"
      style={{ margin: 0, textDecoration: "none", width: "100%" }}
      target="_blank"
      rel="noreferrer"
    >
      <div className={styles.containerOuter}>
        <ResponsiveContainer>
          <div className={styles.containerInner}>
            <Body1Bold
              className={styles.desktopCopy}
              colorClass={ColorClass.White}
              textAlign="center"
            >
              Have feedback, or need help? Join our Discord!
            </Body1Bold>
            <Body1Bold
              className={styles.mobileCopy}
              colorClass={ColorClass.White}
              textAlign="center"
            >
              Have feedback? Join our Discord!
            </Body1Bold>
            <DiscordIcon24 colorValue={ColorValue.White} />
          </div>
        </ResponsiveContainer>
      </div>
    </a>
  );
}
