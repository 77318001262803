import { update } from "idb-keyval";
import LocalStorageKey from "types/enums/LocalStorageKey";

export default async function addLocalStorageProjectId(
  projectId: string
): Promise<void> {
  await update(LocalStorageKey.AllProjectIds, (currProjectIds) => [
    ...(currProjectIds ?? []),
    projectId,
  ]);
}
