import mergeImages from "merge-images";
import fileToDataUri from "utils/fileToDataUri";
import filterNulls from "utils/filterNulls";
// @ts-ignore
import * as gifFrame from "gif-frames";
import GIF from "gif.js";
import invariant from "tiny-invariant";
import { Maybe } from "types/UtilityTypes";

type FrameInfo = {
  data_length: number;
  data_offset: number;
  delay: number;
  dispotal: number;
  has_local_palette: number;
  height: number;
  interlaced: number;
  palette_offset: number;
  transparent_index: number;
  width: number;
  x: number;
  y: number;
};

type Frame = {
  frameIndex: number;
  frameInfo: FrameInfo;
  uri: string;
};

type ParsedFile = {
  type: string;
  uri: string;
  frames?: Frame[];
};

function loadImage(url: string): Promise<HTMLImageElement> {
  return new Promise((resolve, reject) => {
    const image = new Image();
    image.onload = () => resolve(image);
    image.onerror = (error) => reject(error);
    image.src = url;
  });
}

async function blobToBase64(blob: Blob): Promise<string> {
  return new Promise((resolve, _) => {
    const reader = new FileReader();
    reader.onloadend = () => resolve(reader.result as string);
    reader.readAsDataURL(blob);
  });
}

async function stream(pipe: any): Promise<Buffer> {
  return new Promise((resolve, _reject) => {
    const bufs: any = [];
    pipe.on("data", (d: any) => {
      bufs.push(d);
    });
    pipe.on("end", () => {
      const buf = Buffer.concat(bufs);
      resolve(buf);
    });
  });
}

async function parseGif(gifFiles: string): Promise<Frame[]> {
  const frames = await gifFrame({
    url: gifFiles,
    frames: "all",
    outputType: "png",
  });
  const parsed = await Promise.all(
    frames.map(
      async (frame: any): Promise<Frame> => ({
        frameIndex: frame.frameIndex,
        frameInfo: frame.frameInfo,
        uri: await blobToBase64(
          new Blob([await stream(frame.getImage())], { type: "image/png" })
        ),
      })
    )
  );

  return parsed as Frame[];
}

async function mergeFrames(files: ParsedFile[]) {
  const gifs = files.filter((file: ParsedFile) => file.type === "gif");
  const lengths = gifs.map((gif: ParsedFile) => gif.frames!.length);
  const framesLength = Math.min(...lengths);
  // Still refers to the delays of the first gif found at the moment.
  const gif: Frame[] | undefined = gifs[0].frames;

  const frames: Array<Array<string>> = Array.from(Array(framesLength)).map(
    (_, i) =>
      files.map((file: any) =>
        file.type === "gif" ? file.frames[i].uri : file.uri
      )
  );

  const merged = await Promise.all(
    frames.map(async (frame, index) => ({
      ...gif![index],
      uri: await mergeImages(frame),
      image: await loadImage(await mergeImages(frame)),
    }))
  );
  return merged;
}

function getImageColors(image: HTMLImageElement): Array<string> {
  const canvas = document.createElement("canvas");
  const context = canvas.getContext("2d");
  invariant(context != null);
  context.drawImage(image, 0, 0);

  const colors: Array<string> = [];
  for (let i = 0; i < image.width; i++) {
    for (let j = 0; j < image.width; j++) {
      colors.push(
        [...context.getImageData(i, j, 1, 1).data].slice(0, 3).join(".")
      );
    }
  }

  return [...new Set(colors)];
}

function mergeGif(
  frames: Array<{ image: HTMLImageElement; frameInfo: { delay: number } }>
): Promise<Blob> {
  const allColors: Maybe<Array<string>> =
    frames[0].image.width < 256
      ? [
          ...new Set(
            frames.reduce(
              (acc: Array<string>, currVal) => [
                ...acc,
                ...getImageColors(currVal.image),
              ],
              []
            )
          ),
        ]
      : null;
  const allColorsUnique = allColors?.map((color) => color.split(".")).flat();
  return new Promise((resolve, _reject) => {
    const gif = new GIF({
      // GIFs can have a max of 256 colors.
      //
      // If there are at most 256 unique colors
      // used by the GIF, then explicitly pass in these colors.
      //
      // OTHEREWISE, NeuQuant will mess things up... it's stupid.
      // @ts-ignore undocumented feature
      globalPalette:
        allColorsUnique != null && allColorsUnique.length <= 256
          ? allColorsUnique
          : undefined,
      // background: "0x000",
      // transparent: "0x000",
      workers: 2,
      workerScript: process.env.REACT_APP_GIF_URL,
      quality: 1,
    });

    frames.forEach(async (frame) => {
      gif.addFrame(frame.image, {
        // gif-frames has delays in 1/100ths of a second.
        // But gif.js wants delays in ms.
        // So need to multiply by 10.
        delay: frame.frameInfo.delay * 10,
      });
    });

    gif.on("finished", (blob: Blob) => {
      // See https://github.com/jnordberg/gif.js/issues/96
      gif.abort();
      // @ts-ignore
      gif.freeWorkers.forEach((w) => w.terminate());

      resolve(blob);
    });
    gif.render();
  });
}

// Returns a data URI.
export default async function generateSingleImage(
  files: Array<File | null>
): Promise<string> {
  if (files.some((file) => file?.name.includes(".gif"))) {
    const parsedFiles = await Promise.all(
      files.map(async (file) => {
        if (file?.name.includes(".gif")) {
          return {
            type: "gif",
            frames: await parseGif(await fileToDataUri(file)),
          };
        }
        return { type: "image", uri: await fileToDataUri(file as File) };
      })
    );
    const mergedFrames = await mergeFrames(parsedFiles as ParsedFile[]);
    return blobToBase64(await mergeGif(mergedFrames));
  }

  const uris = await Promise.all(
    filterNulls(files).map((file) => fileToDataUri(file))
  );
  return mergeImages(uris);
}
