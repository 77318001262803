/**
 * These should be ordered in the order they appear in the UI.
 */
enum GenerateStep {
  BasicInfo = "Basic Info",
  Metadata = "Metadata",
  LayerOrdering = "Layer Ordering",
  TraitAllocation = "Trait Allocation",
  Generation = "Generation",
}

export default GenerateStep;
