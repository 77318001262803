import useGenerateStep from "hooks/useGenerateStep";
import { Link } from "react-router-dom";
import styles from "css/pages/generate/GenerateSteps.module.css";
import joinClasses from "utils/joinClasses";
import BackgroundColorClass from "types/enums/BackgroundColorClass";
import Body2 from "components/text/Body2";
import ColorClass from "types/enums/ColorClass";
import GenerateStep from "types/enums/GenerateStep";
import getGenerateStepIndex from "utils/getGenerateStepIndex";
import getGenerateStepHref from "utils/getGenerateStepHref";

function Circle({ isFilled }: { isFilled: boolean }): JSX.Element {
  return (
    <div
      className={joinClasses(
        styles.circle,
        isFilled ? BackgroundColorClass.Purple : BackgroundColorClass.White
      )}
    />
  );
}

function Item({
  href,
  isFilled,
  name,
}: {
  href: string;
  isFilled: boolean;
  name: string;
}): JSX.Element {
  return (
    <Link to={href} style={{ textDecoration: "none" }}>
      <div className={styles.item}>
        <Body2 colorClass={ColorClass.White} textAlign="center">
          {name}
        </Body2>
        <Circle isFilled={isFilled} />
      </div>
    </Link>
  );
}

export default function GenerateSteps(): JSX.Element {
  const currentStep = useGenerateStep();

  const items = Object.values(GenerateStep).map((step) => (
    <Item
      href={getGenerateStepHref(step)}
      isFilled={getGenerateStepIndex(currentStep) >= getGenerateStepIndex(step)}
      key={step}
      name={step}
    />
  ));

  return (
    <div className={styles.container}>
      {items}
      <div className={styles.line} />
    </div>
  );
}
