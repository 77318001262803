import styles from "css/pages/how-it-works/HowItWorksPage.module.css";
import { useNavigate } from "react-router-dom";
import ContainerOuter from "components/common/ContainerOuter";
import HowItWorksCard from "components/pages/how-it-works/HowItWorksCard";
import ResponsiveContainer from "components/ResponsiveContainer";
import Body2 from "components/text/Body2";
import usePrefetch from "hooks/usePrefetch";
import ColorClass from "types/enums/ColorClass";
import ImageWebp from "components/images/ImageWebp";
import useLogPageView from "hooks/useLogPageView";

function Card3({
  backOnClick,
  continueOnClick,
}: {
  backOnClick: () => void;
  continueOnClick: () => void;
}): JSX.Element {
  return (
    <HowItWorksCard
      backButton="Back"
      backOnClick={backOnClick}
      continueButton="Try it out!"
      continueOnClick={continueOnClick}
      description="You'll connect Nifty Generator to a single folder containing sub-folders of all the traits."
      title="Formatting Folders for Nifty Generator"
    >
      <ImageWebp
        className={styles.folderStructureImageDesktop}
        src="/images/folder-structure.svg"
      />
      <ImageWebp
        className={styles.folderStructureImageMobile}
        src="/images/folder-structure-mobile.svg"
      />
      <Body2
        className={styles.folderStructureDescription}
        colorClass={ColorClass.Navy}
        textAlign="center"
      >
        Each of these sub-folders should contain all the variants of each trait
        (for example, the Pet folder would have the .PNG files for the Cat and
        the Dog variants).
      </Body2>
    </HowItWorksCard>
  );
}

export default function HowItWorksPage3(): JSX.Element {
  useLogPageView();
  const navigate = useNavigate();
  usePrefetch("/generate");
  usePrefetch("/how-it-works/2");

  return (
    <ContainerOuter>
      <ResponsiveContainer>
        <div className={styles.containerInner}>
          <Card3
            backOnClick={() => navigate("/how-it-works/2")}
            continueOnClick={() => navigate("/generate")}
          />
        </div>
      </ResponsiveContainer>
    </ContainerOuter>
  );
}
