import ColorClass from "types/enums/ColorClass";
import PlainButton from "components/buttons/PlainButton";
import { range } from "utils/range";
import styles from "css/buttons/BigToggleButton.module.css";
import ButtonText from "components/text/ButtonText";

type Props = {
  labels: Array<string>;
  onToggle: (val: number) => void;
  position: number;
};

export default function BigToggleButton({
  labels,
  onToggle,
  position,
}: Props): JSX.Element {
  const numToggles = labels.length;
  const toggleButtons = range(numToggles).map((val) => (
    <PlainButton
      className={styles.toggleButton}
      key={val}
      onClick={() => onToggle(val)}
    >
      <ButtonText
        colorClass={position === val ? ColorClass.White : ColorClass.Navy}
      >
        {labels[val]}
      </ButtonText>
    </PlainButton>
  ));

  return (
    <div
      className={styles.toggle}
      style={{ gridTemplateColumns: `repeat(${numToggles}, minmax(0, 1fr))` }}
    >
      <div
        className={styles.toggleHighlight}
        style={{
          width: `calc(${100 / numToggles}% - 14px)`,
          left: `calc((7px * (2 * ${position} + 1) + ((${
            100 / numToggles
          }% - 14px) * ${position}))`,
        }}
      />
      {toggleButtons}
    </div>
  );
}
