import {
  GenerateConfigContext,
  GenerateConfigContextData,
} from "context/GenerateConfigContext";

import { useContext } from "react";

export default function useGenerateConfigContext(): GenerateConfigContextData {
  return useContext(GenerateConfigContext);
}
