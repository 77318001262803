import ConnectWalletButton from "components/buttons/ConnectWalletButton";
import TextButton from "components/buttons/TextButton";
import GenericModal from "components/modal/GenericModal";
import Body1 from "components/text/Body1";
import styles from "css/modal/GateModal.module.css";
import ColorClass from "types/enums/ColorClass";
import FontClass from "types/enums/FontClass";
import TextButtonTheme from "types/enums/TextButtonTheme";
import { useAnchorWallet } from "@solana/wallet-adapter-react";
import useWhitelistContext from "hooks/useWhitelistContext";

type Props = {
  isShown: boolean;
  onHide: () => void;
};

export default function GateModal({ isShown, onHide }: Props): JSX.Element {
  const wallet = useAnchorWallet();
  const { isWhitelisted, isChecking } = useWhitelistContext();

  let body = (
    <>
      <Body1
        colorClass={ColorClass.Navy}
        className={styles.description}
        textAlign="center"
      >
        You must own a{" "}
        <TextButton
          buttonTheme={TextButtonTheme.Navy}
          display="inline"
          href="https://mycoverse.xyz/"
          fontClass={FontClass.Body1}
          textDecoration="underline"
          type="link_external"
        >
          Mycoverse NFT
        </TextButton>{" "}
        to use premium features (generate &gt;1000 images, GIF support, shuffle
        tool). If you own one, connect your wallet!
      </Body1>
      <div className={styles.button}>
        <ConnectWalletButton />
      </div>
    </>
  );
  let title = "Connect your wallet";

  if (wallet != null) {
    if (isChecking) {
      title = "Please wait...";
      body = (
        <Body1 colorClass={ColorClass.Navy} textAlign="center">
          Please wait a moment as we check your NFT ownership.
        </Body1>
      );
    } else if (isWhitelisted) {
      title = "Thanks!";
      body = (
        <Body1 colorClass={ColorClass.Navy} textAlign="center">
          Thanks for supporting the Mycoverse!
        </Body1>
      );
    } else {
      title = "Sorry 🥲";
      body = (
        <Body1
          className={styles.description}
          colorClass={ColorClass.Navy}
          textAlign="center"
        >
          You don&apos;t own a Mycoverse NFT, so premium features (generate
          &gt;1000 images, GIF support, shuffle tool) are not available.
        </Body1>
      );
    }
  }

  return (
    <GenericModal isShown={isShown} onHide={onHide} title={title}>
      <div className={styles.body}>{body}</div>
    </GenericModal>
  );
}
