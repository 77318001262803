import { set } from "idb-keyval";
import LocalStorageKey from "types/enums/LocalStorageKey";
import prefixKeyWithProjectId from "utils/local-storage/prefixKeyWithProjectId";

export default async function setLocalStorage(
  key: LocalStorageKey,
  val: any
): Promise<void> {
  const prefixedKey = await prefixKeyWithProjectId(key);
  await set(prefixedKey, val);
}
