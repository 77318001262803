import styles from "css/common/GenerateStepsContainer.module.css";
import TextButton from "components/buttons/TextButton";
import LoadingSpinner from "components/loading/LoadingSpinner";
import GenerateSteps from "components/pages/generate/GenerateSteps";
import ResponsiveContainer from "components/ResponsiveContainer";
import Body1 from "components/text/Body1";
import useGenerateConfigContext from "hooks/useGenerateConfigContext";
import ColorClass from "types/enums/ColorClass";
import ColorValue from "types/enums/ColorValue";
import FontClass from "types/enums/FontClass";
import TextButtonTheme from "types/enums/TextButtonTheme";

function NoProject(): JSX.Element {
  return (
    <Body1
      className={styles.noProject}
      colorClass={ColorClass.White}
      textAlign="center"
    >
      Please{" "}
      <TextButton
        buttonTheme={TextButtonTheme.White}
        display="inline"
        fontClass={FontClass.Body1}
        href="/generate"
        textDecoration="underline"
        type="link_internal"
      >
        create a project
      </TextButton>
      .
    </Body1>
  );
}

type Props = {
  children: any;
};

export default function GenerateStepsContainer({
  children,
}: Props): JSX.Element {
  const { projectId } = useGenerateConfigContext();

  const noProject = <NoProject />;
  const content = (
    <>
      <GenerateSteps />
      <div className={styles.content}>{children}</div>
    </>
  );
  const loading = (
    <>
      <GenerateSteps />
      <LoadingSpinner
        className={styles.loading}
        colorValue={ColorValue.White}
        height={32}
        width={32}
      />
    </>
  );

  return (
    <ResponsiveContainer>
      <div className={styles.containerInner}>
        {projectId === undefined
          ? loading
          : projectId == null
          ? noProject
          : content}
      </div>
    </ResponsiveContainer>
  );
}
