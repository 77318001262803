export default async function getImageDimensions(
  dataUri: string
): Promise<{ height: number; width: number }> {
  return new Promise((resolved) => {
    const i = new Image();
    i.onload = function () {
      resolved({ width: i.width, height: i.height });
    };
    i.src = dataUri;
  });
}
