/* eslint-disable jsx-a11y/mouse-events-have-key-events */
import GenericModal from "components/modal/GenericModal";
import styles from "css/modal/ContactUsModal.module.css";
import Body1 from "components/text/Body1";
import ColorClass from "types/enums/ColorClass";
import { useState } from "react";
import TextButton from "components/buttons/TextButton";
import TextButtonTheme from "types/enums/TextButtonTheme";
import FontClass from "types/enums/FontClass";
import TwitterIcon24 from "components/icons/TwitterIcon24";
import ColorValue from "types/enums/ColorValue";
import Body2 from "components/text/Body2";
import ImageWebp from "components/images/ImageWebp";

function TeamMemberProfile({
  description,
  srcNft,
  srcReal,
  twitterHandle,
  twitterHref,
}: {
  description: string;
  srcNft: any;
  srcReal: any;
  twitterHandle: string;
  twitterHref: string;
}): JSX.Element {
  const [isReal, setIsReal] = useState(false);

  return (
    <div className={styles.teamMemberProfile}>
      <ImageWebp
        className={styles.teamMemberProfileImage}
        onMouseOut={() => setIsReal(false)}
        onMouseOver={() => setIsReal(true)}
        src={srcNft}
        style={{ display: !isReal ? "block" : "none" }}
      />
      <ImageWebp
        className={styles.teamMemberProfileImage}
        onMouseOut={() => setIsReal(false)}
        onMouseOver={() => setIsReal(true)}
        src={srcReal}
        style={{ display: isReal ? "block" : "none" }}
      />
      <TextButton
        buttonTheme={TextButtonTheme.Navy}
        className={styles.teamMemberProfileTwitter}
        fontClass={FontClass.Body1}
        href={twitterHref}
        icon={<TwitterIcon24 colorValue={ColorValue.Navy} />}
        type="link_external"
      >
        {twitterHandle}
      </TextButton>
      <Body2
        className={styles.teamMemberProfileDescription}
        colorClass={ColorClass.Navy}
      >
        {description}
      </Body2>
    </div>
  );
}

type Props = {
  isShown: boolean;
  onHide: () => void;
};

export default function ContactUsModal({
  isShown,
  onHide,
}: Props): JSX.Element {
  return (
    <GenericModal isShown={isShown} onHide={onHide} title="Contact Us">
      <div className={styles.body}>
        <Body1
          className={styles.description}
          colorClass={ColorClass.Navy}
          textAlign="center"
        >
          If you&apos;re having issues using Nifty Generator or have suggestions
          for us, please let us know! You can just ping us on{" "}
          <TextButton
            buttonTheme={TextButtonTheme.Navy}
            display="inline"
            fontClass={FontClass.Body1}
            textDecoration="underline"
          >
            Discord
          </TextButton>
          , and we&apos;ll respond as soon as we can 😊
        </Body1>
        <div className={styles.teamMemberProfiles}>
          <TeamMemberProfile
            description="Kat - artist, designer"
            srcNft="images/elves/katherine-elf2.jpg"
            srcReal="images/kat-mushroom3.jpg"
            twitterHandle="petrichorate"
            twitterHref="https://twitter.com/petrichorate"
          />
          <TeamMemberProfile
            description="Matt - engineer"
            srcNft="images/elves/matt-elf2.jpg"
            srcReal="images/matt-mushroom3.jpeg"
            twitterHandle="pencilflip"
            twitterHref="https://twitter.com/pencilflip"
          />
        </div>
      </div>
    </GenericModal>
  );
}
