import { BATCH_SIZE } from "components/pages/generate/generation/GenerationPage";
import { FilesMapDependent, TraitWeights } from "context/GenerateConfigContext";
import Blockchain from "types/enums/Blockchain";
import Metadata from "types/Metadata";
import SolanaMetadata from "types/SolanaMetadata";
import generateMetadataObject from "utils/generateMetadataObject";
import generateSingleImage from "utils/generateSingleImage";
import isDataUriGif from "utils/isDataUriGif";
import randomNumber from "utils/randomNumber";

function arrayRemove<T>(array: Array<T>, start: number): Array<T> {
  array.splice(start, 1);
  return array;
}

function pickRandomIndex<T>(array: Array<T>): number {
  return randomNumber(0, array.length - 1);
}

function existsCombination(
  pickedTraitValues: Array<string>,
  seen: Array<Array<string>>
): boolean {
  const exists = false;
  for (const [_index, array] of seen.entries()) {
    const isEqual =
      array.length === pickedTraitValues.length &&
      array.every((value, index) => value === pickedTraitValues[index]);
    if (isEqual) {
      return true;
    }
  }
  return exists;
}

export default async function generateImages(
  numImages: number,
  filesMap: Map<string, Array<File>>,
  filesMapDependent: FilesMapDependent,
  traitWeights: TraitWeights,
  traitNamesOrdered: Array<string>,
  blockchain: Blockchain,
  description: string,
  name: string,
  seenLayers: Array<Array<string>>,
  setSeenLayers: (val: Array<Array<string>>) => void,
  newImageCallback: (dataUri: string, metadata: Metadata) => void,
  solanaMetadata?: SolanaMetadata
): Promise<Array<string>> {
  // This function gets rid of duplicates
  let noMoreMatches = 0;
  const allGenerated: Array<string> = [];

  // Map from trait names => [value1, value1, value2, value3, ...]
  const traitWeightsExpanded: Map<string, Array<string>> = new Map();
  for (const traitName of traitWeights.keys()) {
    const expanded = [];
    for (const traitValue of traitWeights.get(traitName)!.keys()) {
      for (let i = 0; i < traitWeights.get(traitName)!.get(traitValue)!; i++) {
        expanded.push(traitValue);
      }
    }
    traitWeightsExpanded.set(traitName, expanded);
  }

  const reversed = [...traitNamesOrdered].reverse();

  while (
    noMoreMatches < 20000 &&
    seenLayers.length < numImages &&
    allGenerated.length < BATCH_SIZE
  ) {
    const pickedTraits: Array<{
      traitName: string;
      traitValue: string;
      traitValueIndex: number;
    }> = [];

    // For independent files
    for (const traitName of reversed) {
      const traitValues = traitWeightsExpanded.get(traitName)!;
      const traitValueIndex = pickRandomIndex(traitValues);
      const traitValue = traitValues[traitValueIndex];
      pickedTraits.push({ traitName, traitValue, traitValueIndex });
    }

    const pickedTraitValues = pickedTraits.map(({ traitValue }) => traitValue);

    if (existsCombination(pickedTraitValues, seenLayers)) {
      noMoreMatches++;
      continue;
    }

    noMoreMatches = 0;
    setSeenLayers([...seenLayers, pickedTraitValues]);
    seenLayers.push(pickedTraitValues);
    const files: Array<File | null> = pickedTraits.map(
      ({ traitName, traitValue }) =>
        // Assumes trait values are file names
        filesMap.get(traitName)!.find((file) => file.name === traitValue)!
    );
    const filesCopy = [...files];

    // Dependent files
    // Important to sort so that layering order is correct.
    const dependentTraits = [...filesMapDependent.keys()].sort(
      (a, b) =>
        filesMapDependent.get(a)!.layer - filesMapDependent.get(b)!.layer
    );
    for (const dependentTrait of dependentTraits) {
      const dependentEntry = filesMapDependent.get(dependentTrait)!;

      const matchingIndex = pickedTraits.findIndex(
        ({ traitName }) => traitName === dependentTrait
      );
      const matchingFile = dependentEntry.files.find(
        (file) => file.name === filesCopy[matchingIndex]!.name
      );

      files.splice(dependentEntry.layer, 0, matchingFile ?? null);
    }

    // Generate image
    // eslint-disable-next-line no-await-in-loop
    const generated = await generateSingleImage(files);
    const metadata = generateMetadataObject(
      seenLayers.length - 1,
      blockchain,
      description,
      name,
      pickedTraits,
      isDataUriGif(generated) ? "gif" : "png",
      solanaMetadata
    );
    allGenerated.push(generated);
    newImageCallback(generated, metadata);

    // Remove used traits
    for (const pickedTrait of pickedTraits) {
      const currArr = traitWeightsExpanded.get(pickedTrait.traitName)!;
      arrayRemove(currArr, pickedTrait.traitValueIndex);
      traitWeightsExpanded.set(pickedTrait.traitName, currArr);
    }
  }

  return allGenerated;
}
