import LocalStorageKey from "types/enums/LocalStorageKey";
import setLocalStorage from "utils/local-storage/setLocalStorage";

export default function setStateLocalStorageWrapper<T>(
  setState: (val: T) => void,
  localStorageKey: LocalStorageKey
): (val: T) => void {
  return (val: T) => {
    setLocalStorage(localStorageKey, val);
    setState(val);
  };
}
