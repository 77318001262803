import styles from "css/common/ExplodedAndFinal.module.css";
import joinClasses from "utils/joinClasses";
import ColorValue from "types/enums/ColorValue";
import ImageWebp from "components/images/ImageWebp";

type Props = {
  className?: string;
  isExplodedFirst?: boolean;
  theme?: ColorValue.White | ColorValue.PurplePastel;
};

export default function ExplodedAndFinal({
  className,
  isExplodedFirst,
  theme = ColorValue.White,
}: Props): JSX.Element {
  const exploded = (
    <ImageWebp
      className={styles.exploded}
      src={
        theme === ColorValue.White
          ? "/images/exploded-white.png"
          : "/images/exploded-purple.png"
      }
    />
  );

  const finalElem = (
    <ImageWebp
      className={styles.final}
      style={{ borderColor: theme }}
      src="/images/final.png"
    />
  );

  return (
    <div className={joinClasses(styles.container, className)}>
      {isExplodedFirst ? exploded : finalElem}
      <ImageWebp
        className={styles.longArrowDesktop}
        src={
          theme === ColorValue.White
            ? "/images/long-arrow-right-white.svg"
            : "/images/long-arrow-right-purple.svg"
        }
      />
      <ImageWebp
        className={styles.longArrowMobile}
        src={
          theme === ColorValue.White
            ? "/images/long-arrow-down-white.svg"
            : "/images/long-arrow-down-purple.svg"
        }
      />
      {isExplodedFirst ? finalElem : exploded}
    </div>
  );
}
