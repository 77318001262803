import Header from "components/header/Header";
import GlobalClass from "types/enums/GlobalClass";
import styles from "css/pages/landing/LandingHeaderAndHero.module.css";
import ResponsiveContainer from "components/ResponsiveContainer";
import ButtonWithText from "components/buttons/ButtonWithText";
import ButtonTheme from "types/enums/ButtonTheme";
import Body1 from "components/text/Body1";
import ColorClass from "types/enums/ColorClass";
import ExplodedAndFinal from "components/common/ExplodedAndFinal";
import ImageWebp from "components/images/ImageWebp";

export default function LandingHeaderAndHero(): JSX.Element {
  return (
    <div className={GlobalClass.GradientBg}>
      <Header />
      <ResponsiveContainer>
        <div className={styles.containerInner}>
          <h1 className={styles.headerText}>
            generate your NFT collection with no code
          </h1>
          <ImageWebp className={styles.squiggly} src="/images/squiggly.svg" />
          <ButtonWithText
            buttonTheme={ButtonTheme.Purple}
            className={styles.ctaButton}
            href="/generate"
            type="link_internal"
          >
            Get started
          </ButtonWithText>
          <Body1
            className={styles.description}
            colorClass={ColorClass.Navy}
            textAlign="center"
          >
            Nifty Generator lets you randomly generate NFT images with different
            traits, complete with metadata—no coding needed. Just connect your
            image layers to our tool, and we’ll handle the generation!
          </Body1>
          <ExplodedAndFinal className={styles.explodedAndFinal} />
        </div>
      </ResponsiveContainer>
    </div>
  );
}
