import { useEffect, useState } from "react";
import LocalStorageKey from "types/enums/LocalStorageKey";
import getLocalStorage from "utils/local-storage/getLocalStorage";
import { getMany } from "idb-keyval";
import prefixKeyWithProjectId from "utils/local-storage/prefixKeyWithProjectId";
import useGenerateConfigContext from "hooks/useGenerateConfigContext";

export default function useProjectIdsAndNames(): any {
  const { files } = useGenerateConfigContext();
  const [projectIds, setProjectIds] = useState<Array<string>>([]);
  const [projectNames, setProjectNames] = useState<Array<string>>([]);

  useEffect(() => {
    async function run() {
      const ids = await getLocalStorage(LocalStorageKey.AllProjectIds);
      if (ids == null) {
        return;
      }
      const nameKeys: Array<string> = await Promise.all(
        ids.map((id: string) =>
          prefixKeyWithProjectId(LocalStorageKey.ProjectName, id)
        )
      );
      const names = await getMany(nameKeys);
      setProjectIds(ids);
      setProjectNames(names);
    }

    run();
    // Simple way to make sure creating the example project for this first time
    // updates these values
  }, [files?.length]);

  return {
    projectIdsAndNames: projectIds.map((projectId, index) => ({
      id: projectId,
      name: projectNames[index] ?? `Project ${projectId}`,
    })),
  };
}
