import { initializeApp } from "firebase/app";

const firebaseConfig = {
  apiKey: "AIzaSyBIbfbcEWDG6_7ZYbKeEriBEBSjJhsX-x0",
  authDomain: "nifty-generator.firebaseapp.com",
  projectId: "nifty-generator",
  storageBucket: "nifty-generator.appspot.com",
  messagingSenderId: "884294478555",
  appId: "1:884294478555:web:7ee65566fd1b6edd1efafe",
};

// Initialize Firebase
const firebaseApp = initializeApp(firebaseConfig);
export default firebaseApp;
