import styles from "css/input/InputWithQuestionAndLabel.module.css";
import Body1 from "components/text/Body1";
import QuestionText from "components/text/QuestionText";
import ColorClass from "types/enums/ColorClass";

type Props = {
  children: any;
  label?: string | JSX.Element;
  question?: string | JSX.Element;
};

export default function InputWithQuestionAndLabel({
  children,
  label,
  question,
}: Props): JSX.Element {
  return (
    <div className={styles.container}>
      {question != null && (
        <QuestionText
          className={styles.question}
          colorClass={ColorClass.Navy}
          textAlign="center"
        >
          {question}
        </QuestionText>
      )}
      {label != null && (
        <Body1
          className={styles.label}
          colorClass={ColorClass.Navy}
          textAlign="center"
        >
          {label}
        </Body1>
      )}
      <div className={styles.input}>{children}</div>
    </div>
  );
}
