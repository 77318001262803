import styles from "css/common/FolderGrid.module.css";
import joinClasses from "utils/joinClasses";

export default function FolderGrid({
  children,
  className,
}: {
  children: any;
  className?: string;
}): JSX.Element {
  return <div className={joinClasses(styles.grid, className)}>{children}</div>;
}
