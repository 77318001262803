import Blockchain from "types/enums/Blockchain";
import SolanaMetadata from "types/SolanaMetadata";
import invariant from "tiny-invariant";

/**
 * It's convenient to name folders like 0-Background, 1-Expression, etc. so that they're
 * automatically put in the right order.
 *
 * But, we don't want the "#-" to be part of the trait type in the metadata. So this gets
 * rid of that.
 */
function formatTraitType(traitType: string): string {
  return traitType.replace(/^\d-/, "");
}

// Should adhere to https://docs.opensea.io/docs/metadata-standards
function forEth(
  id: number,
  description: string,
  name: string,
  traits: Array<{ traitName: string; traitValue: string }>
) {
  const metadata = {
    attributes: [] as Array<{ trait_type: string; value: string }>,
    description,
    edition: id + 1,
    // 1-indexed
    name: `${name} #${id + 1}`,
  };

  traits.forEach((trait) => {
    metadata.attributes.push({
      trait_type: formatTraitType(trait.traitName),
      value: trait.traitValue.replace(/.png|.gif/g, ""),
    });
  });

  return metadata;
}

// Should adhere to https://docs.metaplex.com/nft-standardv
// TODO: should make this more customizable, a lot of it is hardcoded
function forSolana(
  id: number,
  description: string,
  name: string,
  traits: Array<{ traitName: string; traitValue: string }>,
  fileType: "png" | "gif",
  solanaMetadata: SolanaMetadata
) {
  const metadata = {
    attributes: [] as Array<{ trait_type: string; value: string }>,
    collection: {
      name: solanaMetadata.collectionName,
      family: solanaMetadata.collectionFamily,
    },
    description,
    external_url:
      solanaMetadata.externalUrl == null
        ? undefined
        : `${solanaMetadata.externalUrl}`,
    name: `${name} #${id}`,
    // Candy Machine will replace this URI when uploading assets.
    // Image field is required so NFT shows properly on Phantom as per metaplex standard.
    image: `${id}.${fileType}`,
    properties: {
      category: "image",
      creators: solanaMetadata.royalties,
      files: [
        {
          // Candy Machine will replace this URI when uploading assets.
          uri: `${id}.${fileType}`,
          type: `image/${fileType}`,
        },
      ],
    },
    seller_fee_basis_points: solanaMetadata.sellerFeeBasisPoints,
    symbol: solanaMetadata.symbol,
  };

  traits.forEach((trait) => {
    metadata.attributes.push({
      trait_type: formatTraitType(trait.traitName),
      value: trait.traitValue.replace(/.png|.gif/g, ""),
    });
  });

  return metadata;
}

export default function generateMetadataObject(
  id: number,
  blockchain: Blockchain,
  description: string,
  name: string,
  traits: Array<{ traitName: string; traitValue: string }>,
  fileType: "png" | "gif",
  solanaMetadata?: SolanaMetadata
) {
  if (blockchain === Blockchain.Ethereum) {
    return forEth(id, description, name, traits);
  }
  if (blockchain === Blockchain.Solana) {
    invariant(solanaMetadata != null);
    return forSolana(id, description, name, traits, fileType, solanaMetadata);
  }
  throw new Error(`Invalid blockchain: ${blockchain}`);
}
