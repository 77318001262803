/* eslint-disable react/jsx-no-constructed-context-values */
/* eslint-disable @typescript-eslint/no-empty-function */
/* eslint-disable @typescript-eslint/no-shadow */
import { Context, createContext, useEffect, useReducer, useState } from "react";
import DS_STORE from "constants/DsStore";
import Blockchain from "types/enums/Blockchain";
import { Maybe, MaybeUndef } from "types/UtilityTypes";
import emptyFunction from "utils/emptyFunction";
import LocalStorageKey from "types/enums/LocalStorageKey";
import setStateLocalStorageWrapper from "utils/local-storage/setStateLocalStorageWrapper";
import setLocalStorage from "utils/local-storage/setLocalStorage";
import { nanoid } from "nanoid";
import addLocalStorageProjectId from "utils/local-storage/addLocalStorageProjectId";
import setLocalStorageProjectId from "utils/local-storage/setLocalStorageProjectId";
import printLocalStorageInfo from "utils/local-storage/printLocalStorageInfo";
import getManyLocalStorage from "utils/local-storage/getManyLocalStorage";
import setManyLocalStorage from "utils/local-storage/setManyLocalStorage";
import generateImagesExt from "utils/generateImages";
import Metadata from "types/Metadata";
import getLocalStorage from "utils/local-storage/getLocalStorage";
import EXAMPLE_PROJECT_ID from "constants/ExampleProjectId";
import Dimensions from "types/Dimensions";
import fileToDataUri from "utils/fileToDataUri";
import getImageDimensions from "utils/getImageDimensions";
import filesToFilesMap from "utils/filesToFilesMap";
import filesToFilesMapDependent from "utils/filesToFilesMapDependent";
import getExampleProjectFiles from "utils/getExampleProjectFiles";
import { range } from "utils/range";
import sumArray from "utils/sumArray";
import addTraitValueExtension from "utils/addTraitValueExtension";
import CreatorRoyalties from "types/CreatorRoyalties";
import { BATCH_SIZE } from "components/pages/generate/generation/GenerationPage";

// 5%
const BASIS_POINTS_DEFAULT = 500;
const ROYALTIES_DEFAULT = [{ address: "", share: 0 }];

export type FilesMapDependent = Map<
  string,
  { files: Array<File>; layer: number }
>;
export type TraitWeights = Map<string, Map<string, number>>;

type RoyaltiesDispatchAction =
  | { type: "add_row" }
  | { type: "remove_row"; index: number }
  | { type: "set_directly"; value: Array<CreatorRoyalties> }
  | { type: "update_address"; address: string; index: number }
  | { type: "update_share"; share: number; index: number };

function royaltiesReducer(
  state: Array<CreatorRoyalties>,
  action: RoyaltiesDispatchAction
): Array<CreatorRoyalties> {
  if (action.type === "add_row") {
    const result = [...state, { address: "", share: 0 }];
    setLocalStorage(LocalStorageKey.MetaRoyalties, result);
    return result;
  }

  if (action.type === "remove_row") {
    const result = state.filter((_, index) => index !== action.index);
    setLocalStorage(LocalStorageKey.MetaRoyalties, result);
    return result;
  }

  if (action.type === "set_directly") {
    setLocalStorage(LocalStorageKey.MetaRoyalties, action.value);
    return action.value;
  }

  if (action.type === "update_address") {
    const result = state.map((row, index) =>
      index === action.index
        ? { address: action.address, share: row.share }
        : row
    );
    setLocalStorage(LocalStorageKey.MetaRoyalties, result);
    return result;
  }

  if (action.type === "update_share") {
    const result = state.map((row, index) =>
      index === action.index
        ? { address: row.address, share: action.share }
        : row
    );
    setLocalStorage(LocalStorageKey.MetaRoyalties, result);
    return result;
  }

  throw new Error("should not reach");
}

type TraitWeightsDispatchAction =
  | {
      type: "update_for_value";
      traitName: string;
      traitValue: string;
      weight: number;
    }
  | {
      type: "update_for_values";
      traitName: string;
      values: Array<{ traitValue: string; weight: number }>;
    }
  | {
      type: "set_directly";
      weights: TraitWeights;
    }
  | {
      type: "allocate_evenly";
      numTotal: number;
      traitName: string;
      traitValues: Array<string>;
    };

function traitWeightsReducer(
  state: TraitWeights,
  action: TraitWeightsDispatchAction
): TraitWeights {
  if (action.type === "update_for_value") {
    const newMap = new Map(state);
    const mapForTrait = newMap.get(action.traitName) ?? new Map();
    mapForTrait.set(action.traitValue, action.weight);
    newMap.set(action.traitName, mapForTrait);

    setLocalStorage(LocalStorageKey.TraitWeights, newMap);

    return newMap;
  }

  if (action.type === "update_for_values") {
    const newMap = new Map(state);
    const mapForTrait = newMap.get(action.traitName) ?? new Map();
    action.values.forEach((value) => {
      const withExt = addTraitValueExtension(
        state,
        action.traitName,
        value.traitValue
      );
      if (withExt != null) {
        mapForTrait.set(withExt, value.weight);
      }
    });
    newMap.set(action.traitName, mapForTrait);

    setLocalStorage(LocalStorageKey.TraitWeights, newMap);

    return newMap;
  }

  if (action.type === "set_directly") {
    setLocalStorage(LocalStorageKey.TraitWeights, action.weights);
    return action.weights;
  }

  if (action.type === "allocate_evenly") {
    const newMap = new Map(state);
    const newMapForTrait = new Map();

    const weights = range(action.traitValues.length).fill(
      Math.floor(action.numTotal / action.traitValues.length)
    );
    const leftover = action.numTotal - sumArray(weights);
    // Now, evenly distribute leftover
    for (let i = 0; i < leftover; i++) {
      weights[i % action.traitValues.length] += 1;
    }

    for (const [i, traitValue] of action.traitValues.entries()) {
      newMapForTrait.set(traitValue, weights[i]);
    }

    newMap.set(action.traitName, newMapForTrait);

    setLocalStorage(LocalStorageKey.TraitWeights, newMap);
    return newMap;
  }

  throw new Error("should not reach");
}

export type GenerateConfigContextData = {
  blockchain: Blockchain;
  fileDimensions: Dimensions;
  files: Maybe<Array<File>>;
  filesMap: Maybe<Map<string, Array<File>>>;
  filesMapDependent: Maybe<FilesMapDependent>;
  folderName: Maybe<string>;
  generatedImagesAndMetadata: Array<{
    dataUri: string;
    metadata: Metadata;
  }>;
  generateMetadata: boolean;
  isDoneGenerating: boolean;
  metaCollectionFamily: Maybe<string>;
  metaCollectionName: Maybe<string>;
  metaDescription: Maybe<string>;
  metaExternalUrl: Maybe<string>;
  metaName: Maybe<string>;
  metaRoyalties: Array<CreatorRoyalties>;
  metaSellerFeeBasisPoints: number;
  metaSymbol: Maybe<string>;
  numImages: number;
  projectId: MaybeUndef<string>;
  // To identify between different saved projects
  projectName: Maybe<string>;
  seenLayers: Array<Array<string>>;
  traitNamesOrdered: Maybe<Array<string>>;
  traitWeights: TraitWeights;

  createNewProject: (val: FileList) => Promise<void>;
  generateImages: (seenLayers: Array<Array<string>>) => Promise<Array<string>>;
  switchProject: (projectId: string) => Promise<void>;
  updateProjectFiles: (val: FileList) => Promise<void>;

  dispatchRoyalties: (action: RoyaltiesDispatchAction) => void;
  dispatchTraitWeights: (action: TraitWeightsDispatchAction) => void;

  setBlockchain: (val: Blockchain) => void;
  setGenerateMetadata: (val: boolean) => void;
  setIsDoneGenerating: (val: boolean) => void;
  setMetaCollectionFamily: (val: string) => void;
  setMetaCollectionName: (val: string) => void;
  setMetaDescription: (val: string) => void;
  setMetaExternalUrl: (val: string) => void;
  setMetaName: (val: string) => void;
  setMetaSellerFeeBasisPoints: (val: number) => void;
  setMetaSymbol: (val: string) => void;
  setNumImages: (val: number) => void;
  setProjectName: (val: string) => void;
  setSeenLayers: (val: Array<Array<string>>) => void;
  setTraitNamesOrdered: (val: Array<string>) => void;
};

export const GenerateConfigContext: Context<GenerateConfigContextData> =
  createContext<GenerateConfigContextData>({
    blockchain: Blockchain.Solana,
    fileDimensions: { height: 0, width: 0 },
    files: null,
    filesMap: null,
    filesMapDependent: null,
    folderName: null,
    generatedImagesAndMetadata: [],
    generateMetadata: true,
    isDoneGenerating: false,
    metaCollectionFamily: null,
    metaCollectionName: null,
    metaDescription: null,
    metaExternalUrl: null,
    metaName: null,
    metaRoyalties: [],
    metaSellerFeeBasisPoints: BASIS_POINTS_DEFAULT,
    metaSymbol: null,
    numImages: 10000,
    projectId: undefined,
    projectName: null,
    seenLayers: [],
    traitNamesOrdered: null,
    traitWeights: new Map(),

    createNewProject: async () => {},
    generateImages: async () => [],
    switchProject: async () => {},
    updateProjectFiles: async () => {},

    dispatchRoyalties: emptyFunction,
    dispatchTraitWeights: emptyFunction,

    setBlockchain: emptyFunction,
    setGenerateMetadata: emptyFunction,
    setIsDoneGenerating: emptyFunction,
    setMetaCollectionFamily: emptyFunction,
    setMetaCollectionName: emptyFunction,
    setMetaDescription: emptyFunction,
    setMetaExternalUrl: emptyFunction,
    setMetaName: emptyFunction,
    setMetaSellerFeeBasisPoints: emptyFunction,
    setMetaSymbol: emptyFunction,
    setNumImages: emptyFunction,
    setProjectName: emptyFunction,
    setSeenLayers: emptyFunction,
    setTraitNamesOrdered: emptyFunction,
  });

type ProviderProps = {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  children: any;
};

export function GenerateConfigContextProvider(
  props: ProviderProps
): JSX.Element {
  const [blockchain, setBlockchain] = useState(Blockchain.Solana);
  const [fileDimensions, setFileDimensions] = useState<Dimensions>({
    height: 0,
    width: 0,
  });
  const [files, setFiles] = useState<Maybe<Array<File>>>(null);
  const [filesMap, setFilesMap] =
    useState<Maybe<Map<string, Array<File>>>>(null);
  const [filesMapDependent, setFilesMapDependent] =
    useState<Maybe<FilesMapDependent>>(null);
  const [folderName, setFolderName] = useState<Maybe<string>>(null);
  const [generatedImagesAndMetadata, setGeneratedImagesAndMetadata] = useState<
    Array<{
      dataUri: string;
      metadata: Metadata;
    }>
  >([]);
  const [generateMetadata, setGenerateMetadata] = useState(true);
  const [isDoneGenerating, setIsDoneGenerating] = useState(false);
  const [metaCollectionFamily, setMetaCollectionFamily] =
    useState<Maybe<string>>(null);
  const [metaCollectionName, setMetaCollectionName] =
    useState<Maybe<string>>(null);
  const [metaDescription, setMetaDescription] = useState<Maybe<string>>(null);
  const [metaExternalUrl, setMetaExternalUrl] = useState<Maybe<string>>(null);
  const [metaName, setMetaName] = useState<Maybe<string>>(null);
  const [metaRoyalties, dispatchRoyalties] = useReducer<
    typeof royaltiesReducer
  >(royaltiesReducer, ROYALTIES_DEFAULT);
  const [metaSellerFeeBasisPoints, setMetaSellerFeeBasisPoints] =
    useState<number>(BASIS_POINTS_DEFAULT);
  const [metaSymbol, setMetaSymbol] = useState<Maybe<string>>(null);
  const [numImages, setNumImages] = useState(10000);
  const [projectId, setProjectId] = useState<MaybeUndef<string>>(undefined);
  const [projectName, setProjectName] = useState<Maybe<string>>(null);
  const [seenLayers, setSeenLayers] = useState<Array<Array<string>>>([]);
  const [traitNamesOrdered, setTraitNamesOrdered] =
    useState<Maybe<Array<string>>>(null);
  const [traitWeights, dispatchTraitWeights] = useReducer<
    typeof traitWeightsReducer
  >(traitWeightsReducer, new Map());

  async function setFilesAndFilesMap(
    val: Maybe<FileList | Array<File>>,
    relativePaths?: Array<string>
  ) {
    if (val == null) {
      setFiles(null);
      setFilesMap(null);
      setTraitNamesOrdered(null);
      return { files: null, filesMap: null };
    }

    const filesInner = [...val].filter((file) => file.name !== DS_STORE);
    setFiles(filesInner);
    const filesMapInner = filesToFilesMap(filesInner, relativePaths);
    setFilesMap(filesMapInner);

    if (filesInner.length > 0) {
      const exampleDataUri = await fileToDataUri(filesInner[0]);
      const dimensions = await getImageDimensions(exampleDataUri);
      setFileDimensions(dimensions);
    }

    const filesMapDependentInner = filesToFilesMapDependent(
      filesInner,
      relativePaths
    );
    setFilesMapDependent(filesMapDependentInner);

    if (filesInner.length > 0) {
      // @ts-ignore
      if (filesInner[0].webkitRelativePath.length > 0) {
        // @ts-ignore
        setFolderName(filesInner[0].webkitRelativePath.split("/")[0]);
      } else if (relativePaths != null) {
        setFolderName(relativePaths[0].split("/")[0]);
      }
    }

    return {
      files: filesInner,
      filesMap: filesMapInner,
      filesMapDependent: filesMapDependentInner,
    };
  }

  async function reloadProject() {
    console.log("Reloading...");
    const [
      blockchain,
      files,
      fileRelativePaths,
      generateMetadata,
      metaCollectionFamily,
      metaCollectionName,
      metaDescription,
      metaExternalUrl,
      metaName,
      metaRoyalties,
      metaSellerFeeBasisPoints,
      metaSymbol,
      numImages,
      projectId,
      projectName,
      traitNamesOrdered,
      traitWeights,
    ] = await getManyLocalStorage([
      LocalStorageKey.Blockchain,
      LocalStorageKey.Files,
      LocalStorageKey.FileRelativePaths,
      LocalStorageKey.GenerateMetadata,
      LocalStorageKey.MetaCollectionFamily,
      LocalStorageKey.MetaCollectionName,
      LocalStorageKey.MetaDescription,
      LocalStorageKey.MetaExternalUrl,
      LocalStorageKey.MetaName,
      LocalStorageKey.MetaRoyalties,
      LocalStorageKey.MetaSellerFeeBasisPoints,
      LocalStorageKey.MetaSymbol,
      LocalStorageKey.NumImages,
      LocalStorageKey.ProjectId,
      LocalStorageKey.ProjectName,
      LocalStorageKey.TraitNamesOrdered,
      LocalStorageKey.TraitWeights,
    ]);

    setBlockchain(blockchain ?? Blockchain.Solana);
    setGenerateMetadata(generateMetadata ?? true);
    setMetaCollectionFamily(metaCollectionFamily);
    setMetaCollectionName(metaCollectionName);
    setMetaDescription(metaDescription);
    setMetaExternalUrl(metaExternalUrl);
    setMetaName(metaName);
    setMetaSellerFeeBasisPoints(
      metaSellerFeeBasisPoints ?? BASIS_POINTS_DEFAULT
    );
    setMetaSymbol(metaSymbol);
    setNumImages(numImages ?? 10000);
    setProjectId(projectId ?? null);
    setProjectName(projectName);
    setTraitNamesOrdered(traitNamesOrdered);

    if (projectId != null) {
      dispatchRoyalties({
        type: "set_directly",
        value:
          metaRoyalties != null && metaRoyalties.length > 0
            ? metaRoyalties
            : ROYALTIES_DEFAULT,
      });
      dispatchTraitWeights({
        type: "set_directly",
        weights: traitWeights ?? new Map(),
      });
    }

    const { filesMap } = await setFilesAndFilesMap(files, fileRelativePaths);

    if (traitNamesOrdered == null && filesMap != null) {
      setTraitNamesOrdered([...filesMap.keys()]);
    }

    setGeneratedImagesAndMetadata([]);
  }

  useEffect(() => {
    async function run() {
      await reloadProject();
      await printLocalStorageInfo();
    }

    run();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  // Create example project, if necessary
  useEffect(() => {
    async function run() {
      const allProjectIds = await getLocalStorage(
        LocalStorageKey.AllProjectIds
      );

      if (allProjectIds != null && allProjectIds.includes(EXAMPLE_PROJECT_ID)) {
        return;
      }

      await addLocalStorageProjectId(EXAMPLE_PROJECT_ID);

      const exampleProjectFiles = getExampleProjectFiles();
      const exampleFileList = exampleProjectFiles.map(({ file }) => file);
      const exampleFileRelativePaths = exampleProjectFiles.map(
        ({ relPath }) => relPath
      );
      const { files, filesMap } = await setFilesAndFilesMap(
        exampleFileList,
        exampleFileRelativePaths
      );

      if (files != null && filesMap != null) {
        await setManyLocalStorage(
          [
            LocalStorageKey.Files,
            LocalStorageKey.FileRelativePaths,
            LocalStorageKey.ProjectName,
            LocalStorageKey.NumImages,
            LocalStorageKey.TraitWeights,
          ],
          // @ts-ignore
          [files, exampleFileRelativePaths, "Example Project", 27, new Map()],
          EXAMPLE_PROJECT_ID
        );
      }
    }

    run();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <GenerateConfigContext.Provider
      value={{
        blockchain,
        fileDimensions,
        files,
        filesMap,
        filesMapDependent,
        folderName,
        generatedImagesAndMetadata,
        generateMetadata,
        isDoneGenerating,
        metaCollectionFamily,
        metaCollectionName,
        metaDescription,
        metaExternalUrl,
        metaName,
        metaRoyalties,
        metaSellerFeeBasisPoints,
        metaSymbol,
        numImages,
        projectId,
        projectName,
        seenLayers,
        traitNamesOrdered,
        traitWeights,

        createNewProject: async (fileList) => {
          const newProjectId = nanoid(10);
          await Promise.all([
            setLocalStorageProjectId(newProjectId),
            addLocalStorageProjectId(newProjectId),
          ]);

          const { files, filesMap } = await setFilesAndFilesMap(fileList);
          if (files != null && filesMap != null) {
            await setManyLocalStorage(
              [LocalStorageKey.Files, LocalStorageKey.FileRelativePaths],
              // @ts-ignore
              [files, files.map((file) => file.webkitRelativePath)]
            );
          }

          await reloadProject();
          await printLocalStorageInfo();
        },
        generateImages: async (seenLayers: Array<Array<string>>) => {
          setGeneratedImagesAndMetadata([]);
          const generated = await generateImagesExt(
            numImages,
            filesMap!,
            filesMapDependent ?? new Map(),
            traitWeights,
            traitNamesOrdered!,
            blockchain,
            metaDescription ?? "",
            metaName ?? "",
            [...seenLayers],
            setSeenLayers,
            (newImage, newMetadata) => {
              setGeneratedImagesAndMetadata((curr) => [
                ...curr,
                { dataUri: newImage, metadata: newMetadata },
              ]);
            },
            {
              collectionFamily: metaCollectionFamily ?? "",
              collectionName: metaCollectionName ?? "",
              externalUrl: metaExternalUrl,
              royalties: metaRoyalties,
              sellerFeeBasisPoints: metaSellerFeeBasisPoints,
              symbol: metaSymbol ?? "",
            }
          );
          if (generated.length < BATCH_SIZE) {
            setIsDoneGenerating(true);
          }
          return generated;
        },
        switchProject: async (projectId) => {
          await setLocalStorageProjectId(projectId);
          await reloadProject();
        },
        updateProjectFiles: async (fileList) => {
          const { files, filesMap } = await setFilesAndFilesMap(fileList);

          if (files != null && filesMap != null) {
            await setManyLocalStorage(
              [LocalStorageKey.Files, LocalStorageKey.FileRelativePaths],
              // @ts-ignore
              [files, files.map((file) => file.webkitRelativePath)]
            );

            setStateLocalStorageWrapper(
              setTraitNamesOrdered,
              LocalStorageKey.TraitNamesOrdered
            )([...filesMap.keys()]);

            setLocalStorage(LocalStorageKey.TraitWeights, new Map());
          }

          await reloadProject();
          await printLocalStorageInfo();
        },

        dispatchRoyalties,
        dispatchTraitWeights,

        setBlockchain: setStateLocalStorageWrapper(
          setBlockchain,
          LocalStorageKey.Blockchain
        ),
        setGenerateMetadata: setStateLocalStorageWrapper(
          setGenerateMetadata,
          LocalStorageKey.GenerateMetadata
        ),
        setIsDoneGenerating,
        setMetaCollectionFamily: setStateLocalStorageWrapper(
          setMetaCollectionFamily,
          LocalStorageKey.MetaCollectionFamily
        ),
        setMetaCollectionName: setStateLocalStorageWrapper(
          setMetaCollectionName,
          LocalStorageKey.MetaCollectionName
        ),
        setMetaDescription: setStateLocalStorageWrapper(
          setMetaDescription,
          LocalStorageKey.MetaDescription
        ),
        setMetaExternalUrl: setStateLocalStorageWrapper(
          setMetaExternalUrl,
          LocalStorageKey.MetaExternalUrl
        ),
        setMetaName: setStateLocalStorageWrapper(
          setMetaName,
          LocalStorageKey.MetaName
        ),
        setMetaSellerFeeBasisPoints: setStateLocalStorageWrapper(
          setMetaSellerFeeBasisPoints,
          LocalStorageKey.MetaSellerFeeBasisPoints
        ),
        setMetaSymbol: setStateLocalStorageWrapper(
          setMetaSymbol,
          LocalStorageKey.MetaSymbol
        ),
        setNumImages: setStateLocalStorageWrapper(
          setNumImages,
          LocalStorageKey.NumImages
        ),
        setProjectName: setStateLocalStorageWrapper(
          setProjectName,
          LocalStorageKey.ProjectName
        ),
        setSeenLayers,
        setTraitNamesOrdered: setStateLocalStorageWrapper(
          setTraitNamesOrdered,
          LocalStorageKey.TraitNamesOrdered
        ),
      }}
    >
      {props.children}
    </GenerateConfigContext.Provider>
  );
}
