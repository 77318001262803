import { DetailedHTMLProps, ImgHTMLAttributes } from "react";

type Props = DetailedHTMLProps<
  ImgHTMLAttributes<HTMLImageElement>,
  HTMLImageElement
>;

/**
 * Wrapper of the built-in img element that uses webp resources.
 */
export default function ImageWebp(props: Props): JSX.Element {
  const splits = props.src!.split("/");
  const dir = splits.slice(0, splits.length - 1).join("/");
  const name = splits[splits.length - 1].split(".")[0];
  // // We expect the webp file to be in the same folder and have the same name as the png/jpeg.
  const srcWebp = `${dir}/${name}.webp`;

  return (
    <picture>
      {!props.src!.includes(".svg") && (
        <source srcSet={srcWebp} type="image/webp" />
      )}
      <source srcSet={props.src} />
      <img className={props.className} src={props.src} />
    </picture>
  );
}
