import GenericModal from "components/modal/GenericModal";
import styles from "css/modal/MetadataModal.module.css";
import Metadata from "types/Metadata";

type Props = {
  isShown: boolean;
  metadata: Metadata;
  onHide: () => void;
};

export default function MetadataModal({
  isShown,
  metadata,
  onHide,
}: Props): JSX.Element {
  return (
    <GenericModal className={styles.modal} isShown={isShown} onHide={onHide}>
      <pre className={styles.pre}>{JSON.stringify(metadata, null, 2)}</pre>
    </GenericModal>
  );
}
