import styles from "css/header/HeaderDesktop.module.css";
import TextButton from "components/buttons/TextButton";
import ResponsiveContainer from "components/ResponsiveContainer";
import Header1 from "components/text/Header1";
import FontClass from "types/enums/FontClass";
import GlobalClass from "types/enums/GlobalClass";
import ImageWebp from "components/images/ImageWebp";
import ConnectWalletButton from "components/buttons/ConnectWalletButton";
import useWindowDimensions from "hooks/useWindowDimensions";

export default function HeaderDesktop(): JSX.Element {
  const { width } = useWindowDimensions();

  return (
    <ResponsiveContainer>
      <Header1 className={styles.header} textAlign="center">
        <div className={styles.left}>
          <a className={GlobalClass.HideText} href="/">
            <ImageWebp className={styles.logo} src="/images/logo.svg" />
          </a>
        </div>
        <div className={styles.right}>
          <TextButton
            fontClass={FontClass.QuestionText}
            href="/generate"
            type="link_internal"
          >
            Generate
          </TextButton>
          {width > 1280 && (
            <TextButton
              fontClass={FontClass.QuestionText}
              href="/shuffle"
              type="link_internal"
            >
              Shuffle
            </TextButton>
          )}
          <TextButton
            fontClass={FontClass.QuestionText}
            href="/how-it-works"
            type="link_internal"
          >
            How it works
          </TextButton>
          <ConnectWalletButton />
        </div>
      </Header1>
    </ResponsiveContainer>
  );
}
