/* eslint-disable react/jsx-no-bind */
/* eslint-disable @typescript-eslint/no-shadow */
import ContainerOuter from "components/common/ContainerOuter";
import GenerateStepsContainer from "components/common/GenerateStepsContainer";
import styles from "css/pages/generate/layer-ordering/LayerOrderingPage.module.css";
import InputWithQuestionAndLabel from "components/input/InputWithQuestionAndLabel";
import useGenerateConfigContext from "hooks/useGenerateConfigContext";
import {
  DragDropContext,
  DropResult,
  Droppable,
  Draggable,
} from "react-beautiful-dnd";
import invariant from "tiny-invariant";
import { Maybe } from "types/UtilityTypes";
import ButtonText from "components/text/ButtonText";
import ColorClass from "types/enums/ColorClass";
import ColorValue from "types/enums/ColorValue";
import MoveHandleIcon from "components/icons/MoveHandleIcon";
import NextButton from "components/buttons/NextButton";
import NextAndBackButton from "components/buttons/NextAndBackButton";
import BackButton from "components/buttons/BackButton";
import ElementMobileNotSupported from "components/hoc/ElementMobileNotSupported";
import useLogPageView from "hooks/useLogPageView";

function reorder<T>(
  list: Array<T>,
  startIndex: number,
  endIndex: number
): Array<T> {
  const result = Array.from(list);
  const [removed] = result.splice(startIndex, 1);
  result.splice(endIndex, 0, removed);

  return result;
}

function OrderingInput(): Maybe<JSX.Element> {
  const { filesMap, traitNamesOrdered, setTraitNamesOrdered } =
    useGenerateConfigContext();

  function onDragEnd(result: DropResult) {
    invariant(traitNamesOrdered != null, "Must be true");

    // dropped outside the list
    if (!result.destination) {
      return;
    }

    const items = reorder(
      traitNamesOrdered,
      result.source.index,
      result.destination.index
    );

    setTraitNamesOrdered(items);
  }

  if (traitNamesOrdered == null || filesMap == null) {
    return null;
  }

  return (
    <InputWithQuestionAndLabel
      label="Traits at the top will be the top layer of each generated image."
      question="Order the traits from top to bottom"
    >
      <DragDropContext onDragEnd={onDragEnd}>
        <Droppable droppableId="droppable">
          {(provided) => (
            <div
              className={styles.list}
              {...provided.droppableProps}
              ref={provided.innerRef}
            >
              {traitNamesOrdered.map((name, index) => (
                <Draggable key={name} draggableId={name} index={index}>
                  {(provided) => (
                    <div
                      className={styles.item}
                      ref={provided.innerRef}
                      {...provided.draggableProps}
                      {...provided.dragHandleProps}
                    >
                      <MoveHandleIcon colorValue={ColorValue.Navy} />
                      <div className={styles.itemRight}>
                        <ButtonText colorClass={ColorClass.Navy}>
                          {name}
                        </ButtonText>
                        <ButtonText colorClass={ColorClass.PurplePastel}>
                          {filesMap.get(name)!.length}
                        </ButtonText>
                      </div>
                    </div>
                  )}
                </Draggable>
              ))}
              {provided.placeholder}
            </div>
          )}
        </Droppable>
      </DragDropContext>
    </InputWithQuestionAndLabel>
  );
}

export default function LayerOrderingPage(): JSX.Element {
  useLogPageView();
  return (
    <ElementMobileNotSupported>
      <ContainerOuter>
        <GenerateStepsContainer>
          <OrderingInput />
          <NextAndBackButton>
            <BackButton href="/generate/metadata" />
            <NextButton href="/generate/trait-allocation" />
          </NextAndBackButton>
        </GenerateStepsContainer>
      </ContainerOuter>
    </ElementMobileNotSupported>
  );
}
