const REGEX = /\[(\d+)\]-/;

export default function isDependentFolder(folderName: string): {
  result: boolean;
  layer?: number;
  traitName?: string;
} {
  const match = folderName.match(REGEX);

  if (match == null) {
    return { result: false };
  }

  const traitName = folderName.replace(match[0], "");

  return { result: true, layer: Number(match[1]), traitName };
}
