import BackgroundColorClass from "types/enums/BackgroundColorClass";
import styles from "css/footer/FooterShort.module.css";
import ResponsiveContainer from "components/ResponsiveContainer";
import TextButton from "components/buttons/TextButton";
import TextButtonTheme from "types/enums/TextButtonTheme";
import FontClass from "types/enums/FontClass";
import GlobalClass from "types/enums/GlobalClass";
import ContactUsModal from "components/modal/ContactUsModal";
import { useState } from "react";
import TwitterIcon24 from "components/icons/TwitterIcon24";
import ColorValue from "types/enums/ColorValue";
import DiscordIcon24 from "components/icons/DiscordIcon24";
import Body2 from "components/text/Body2";
import ColorClass from "types/enums/ColorClass";
import ImageWebp from "components/images/ImageWebp";

function Buttons({
  onClickContactUs,
}: {
  onClickContactUs: () => void;
}): JSX.Element {
  return (
    <div className={styles.buttons}>
      <TextButton
        buttonTheme={TextButtonTheme.White}
        fontClass={FontClass.Body1}
        href="/generate"
        type="link_internal"
      >
        Generate
      </TextButton>
      <TextButton
        buttonTheme={TextButtonTheme.White}
        fontClass={FontClass.Body1}
        href="/shuffle"
        type="link_internal"
      >
        Shuffle
      </TextButton>
      <TextButton
        buttonTheme={TextButtonTheme.White}
        fontClass={FontClass.Body1}
        onClick={onClickContactUs}
      >
        Contact us
      </TextButton>
      <TextButton
        buttonTheme={TextButtonTheme.White}
        fontClass={FontClass.Body1}
        href="/how-it-works"
        type="link_internal"
      >
        How it works
      </TextButton>
    </div>
  );
}

export default function FooterShort(): JSX.Element {
  const [isShown, setIsShown] = useState(false);

  return (
    <>
      <ContactUsModal isShown={isShown} onHide={() => setIsShown(false)} />
      <div className={BackgroundColorClass.Purple}>
        <ResponsiveContainer>
          <div className={styles.containerInner}>
            <a className={GlobalClass.HideText} href="/">
              <ImageWebp className={styles.logo} src="/images/logo.svg" />
            </a>
            <Buttons onClickContactUs={() => setIsShown(true)} />
            <div className={styles.icons}>
              <a href="https://twitter.com/themycoverse">
                <TwitterIcon24 colorValue={ColorValue.White} />
              </a>
              <a href="https://discord.gg/hEJhj4GdSa">
                <DiscordIcon24 colorValue={ColorValue.White} />
              </a>
            </div>
            <Body2 colorClass={ColorClass.White}>
              a{" "}
              <a className={styles.mycoverseLink} href="https://mycoverse.xyz/">
                Mycoverse
              </a>{" "}
              project
            </Body2>
          </div>
        </ResponsiveContainer>
      </div>
    </>
  );
}
