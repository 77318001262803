import amplitude, { AmplitudeClient } from "amplitude-js";
import { Maybe } from "types/UtilityTypes";
import isProd from "utils/isProd";

let amplitudeInstance: Maybe<AmplitudeClient> = null;

export default function getAmplitude(): AmplitudeClient {
  if (amplitudeInstance != null) {
    return amplitudeInstance;
  }

  amplitudeInstance = amplitude.getInstance();
  amplitudeInstance!.init("fa9c6ab39fda985637f54fdf86e80e34");
  if (!isProd()) {
    amplitudeInstance!.setDeviceId("DEV");
  }
  return amplitudeInstance!;
}
