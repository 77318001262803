import GenericModal from "components/modal/GenericModal";
import styles from "css/modal/JoinDiscordModal.module.css";
import Body1 from "components/text/Body1";
import ButtonWithText from "components/buttons/ButtonWithText";
import ButtonTheme from "types/enums/ButtonTheme";
import ColorClass from "types/enums/ColorClass";
import TextButton from "components/buttons/TextButton";
import TextButtonTheme from "types/enums/TextButtonTheme";
import FontClass from "types/enums/FontClass";
import DiscordIcon24 from "components/icons/DiscordIcon24";
import ColorValue from "types/enums/ColorValue";

import LoadingSpinner from "components/loading/LoadingSpinner";

type Props = {
  isLoading: boolean;
  isShown: boolean;
  onClick: () => void;
  onHide: () => void;
};

export default function JoinDiscordModal({
  isLoading,
  isShown,
  onClick,
  onHide,
}: Props): JSX.Element {
  return (
    <GenericModal isShown={isShown} onHide={onHide} title="Download">
      <div className={styles.body}>
        <Body1 colorClass={ColorClass.Navy} textAlign="center">
          If you&apos;re trying out Nifty Generator, we&apos;d love to have you
          join our Discord! It&apos;ll give you a direct line of communication
          with us so that you can share your feedback, request new features, or
          report issues.
        </Body1>
        <ButtonWithText
          buttonTheme={ButtonTheme.Purple}
          className={styles.ctaButton}
          onClick={onClick}
        >
          {isLoading ? (
            <LoadingSpinner
              colorValue={ColorValue.White}
              height={24}
              width={24}
            />
          ) : (
            "Download files"
          )}
        </ButtonWithText>
        <TextButton
          buttonTheme={TextButtonTheme.Navy}
          className={styles.secondaryButton}
          fontClass={FontClass.Body1}
          href="https://discord.gg/hEJhj4GdSa"
          icon={<DiscordIcon24 colorValue={ColorValue.Navy} />}
          type="link_external"
        >
          Join Discord
        </TextButton>
      </div>
    </GenericModal>
  );
}
