import styles from "css/pages/how-it-works/HowItWorksPage.module.css";
import { useNavigate } from "react-router-dom";
import ContainerOuter from "components/common/ContainerOuter";
import ExplodedAndFinal from "components/common/ExplodedAndFinal";
import HowItWorksCard from "components/pages/how-it-works/HowItWorksCard";
import ResponsiveContainer from "components/ResponsiveContainer";
import usePrefetch from "hooks/usePrefetch";
import ColorValue from "types/enums/ColorValue";
import useLogPageView from "hooks/useLogPageView";

function Card1({
  continueOnClick,
}: {
  continueOnClick: () => void;
}): JSX.Element {
  return (
    <HowItWorksCard
      continueButton="Continue"
      continueOnClick={continueOnClick}
      description={
        <>
          When you’re making a generative NFT collection, each final image will
          be made out of different traits that can be mixed and matched.
          <br /> <br />
          For instance, this image of an elf is actually made up of 5 image
          layers stacked on top of each other: the cat, the body, the
          expression, the outfit, and the hair.
        </>
      }
      title="Preparing Art Files for Nifty Generator"
    >
      <ExplodedAndFinal
        isExplodedFirst={false}
        theme={ColorValue.PurplePastel}
      />
    </HowItWorksCard>
  );
}

export default function HowItWorksPage1(): JSX.Element {
  useLogPageView();
  const navigate = useNavigate();
  usePrefetch("/how-it-works/2");

  return (
    <ContainerOuter>
      <ResponsiveContainer>
        <div className={styles.containerInner}>
          <Card1 continueOnClick={() => navigate("/how-it-works/2")} />
        </div>
      </ResponsiveContainer>
    </ContainerOuter>
  );
}
