import { Connection, PublicKey } from "@solana/web3.js";
import { TOKEN_PROGRAM_ID } from "@solana/spl-token";

type StringPublicKey = string;
type AnyPublicKey = StringPublicKey | PublicKey;

export default async function getMintAddressesByOwner(
  connection: Connection,
  owner: AnyPublicKey
) {
  return (
    await connection.getParsedTokenAccountsByOwner(new PublicKey(owner), {
      programId: TOKEN_PROGRAM_ID,
    })
  ).value
    .map(({ pubkey, account }) => ({
      data: account.data,
      pubkey: pubkey.toBase58(),
    }))
    .filter(
      ({ data }) =>
        data.parsed.info.tokenAmount.uiAmount > 0 &&
        data.parsed.info.tokenAmount.decimals === 0
    )
    .map((token) => token.data.parsed.info.mint);
}
