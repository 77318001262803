import Marquee from "react-fast-marquee";
import styles from "css/pages/landing/LandingMarquee.module.css";
import ResponsiveContainer from "components/ResponsiveContainer";
import Body2 from "components/text/Body2";
import ColorClass from "types/enums/ColorClass";
import BackgroundColorClass from "types/enums/BackgroundColorClass";
import { range } from "utils/range";
import ImageWebp from "components/images/ImageWebp";

const ELVES = range(10).map((num) => `images/elves/${num + 1}.png`);

function MarqueeSection(): JSX.Element {
  return (
    <Marquee gradient={false} speed={55}>
      {/* Repeat for large screens (to avoid empty space) */}
      {[...ELVES, ...ELVES].map((value, index) => (
        <ImageWebp
          className={styles.image}
          // eslint-disable-next-line react/no-array-index-key
          key={index}
          src={value}
        />
      ))}
    </Marquee>
  );
}

export default function LandingMarquee(): JSX.Element {
  return (
    <div className={styles.containerOuter}>
      <MarqueeSection />
      <div className={BackgroundColorClass.PurplePastel}>
        <ResponsiveContainer>
          <div className={styles.containerInner}>
            <Body2 colorClass={ColorClass.Purple} textAlign="center">
              NFT images created with Nifty Generator for:
            </Body2>
            <a href="https://mycoverse.xyz/">
              <ImageWebp src="/images/mycoverse.svg" />
            </a>
          </div>
        </ResponsiveContainer>
      </div>
    </div>
  );
}
