import Footer from "components/footer/Footer";
import LandingHeaderAndHero from "components/pages/landing/LandingHeaderAndHero";
import LandingInfo from "components/pages/landing/LandingInfo";
import LandingMarquee from "components/pages/landing/LandingMarquee";
import useLogPageView from "hooks/useLogPageView";

export default function LandingPage(): JSX.Element {
  useLogPageView();
  return (
    <div>
      <LandingHeaderAndHero />
      <LandingMarquee />
      <LandingInfo />
      <Footer />
    </div>
  );
}
