import DS_STORE from "constants/DsStore";
import path from "path";
import invariant from "tiny-invariant";
import isDependentFolder from "utils/isDependentFolder";
import { FilesMapDependent } from "context/GenerateConfigContext";

export default function filesToFilesMapDependent(
  files: Array<File>,
  relativePaths?: Array<string>
): FilesMapDependent {
  const filesMap: FilesMapDependent = new Map();

  for (const [index, file] of files.entries()) {
    if (file.name === DS_STORE) {
      continue;
    }

    // @ts-ignore
    const relPath = file.webkitRelativePath;
    const relPathFallback = relativePaths == null ? null : relativePaths[index];
    const key = path
      .dirname(relPathFallback != null ? relPathFallback : relPath)
      .split("/")[1];
    invariant(key != null, `Invalid directory structure, key = ${key}`);

    const isDependent = isDependentFolder(key);
    if (!isDependent.result) {
      continue;
    }

    const { layer, traitName } = isDependent;

    invariant(layer != null, "Layer cannot be null");
    invariant(traitName != null, "Trait name cannot be null");

    if (filesMap.has(traitName)) {
      filesMap.set(traitName, {
        files: [...filesMap.get(traitName)!.files, file],
        layer,
      });
    } else {
      filesMap.set(traitName, { files: [file], layer });
    }
  }

  return filesMap;
}
