import { setMany } from "idb-keyval";
import LocalStorageKey from "types/enums/LocalStorageKey";
import getLocalStorage from "utils/local-storage/getLocalStorage";
import prefixKeyWithProjectId from "utils/local-storage/prefixKeyWithProjectId";

export default async function setManyLocalStorage(
  keys: Array<LocalStorageKey>,
  values: Array<any>,
  projectId?: string
): Promise<void> {
  // eslint-disable-next-line no-param-reassign
  projectId =
    projectId == null
      ? await getLocalStorage(LocalStorageKey.ProjectId)
      : projectId;
  const prefixedKeys = await Promise.all(
    keys.map((key) => {
      if (
        [LocalStorageKey.AllProjectIds, LocalStorageKey.ProjectId].includes(key)
      ) {
        return key;
      }
      return prefixKeyWithProjectId(key, projectId);
    })
  );

  await setMany(prefixedKeys.map((key, index) => [key, values[index]]));
}
