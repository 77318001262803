import LocalStorageKey from "types/enums/LocalStorageKey";
import getManyLocalStorage from "utils/local-storage/getManyLocalStorage";
import logIfNotProd from "utils/logIfNotProd";

/**
 * For debugging.
 */
export default async function printLocalStorageInfo(): Promise<void> {
  const [
    allProjectIds,
    projectId,
    blockchain,
    files,
    fileRelativePaths,
    generateMetadata,
    numImages,
    metaDescription,
    metaName,
    projectName,
    traitNamesOrdered,
    traitWeights,
  ] = await getManyLocalStorage([
    LocalStorageKey.AllProjectIds,
    LocalStorageKey.ProjectId,
    LocalStorageKey.Blockchain,
    LocalStorageKey.Files,
    LocalStorageKey.FileRelativePaths,
    LocalStorageKey.GenerateMetadata,
    LocalStorageKey.NumImages,
    LocalStorageKey.MetaDescription,
    LocalStorageKey.MetaName,
    LocalStorageKey.ProjectName,
    LocalStorageKey.TraitNamesOrdered,
    LocalStorageKey.TraitWeights,
  ]);

  logIfNotProd("=============================================");
  logIfNotProd(`PROJECT ID: ${projectId}`);
  logIfNotProd(`allProjectIds: ${allProjectIds}`);
  logIfNotProd(`blockchain: ${blockchain}`);
  logIfNotProd(`files: ${files}`);
  logIfNotProd(`fileRelativePaths: ${fileRelativePaths}`);
  logIfNotProd(`generateMetadata: ${generateMetadata}`);
  logIfNotProd(`numImages: ${numImages}`);
  logIfNotProd(`metaDescription: ${metaDescription}`);
  logIfNotProd(`metaName: ${metaName}`);
  logIfNotProd(`projectName: ${projectName}`);
  logIfNotProd(`traitNamesOrdered: ${traitNamesOrdered}`);
  logIfNotProd(`traitWeights: ${traitWeights}`);
  logIfNotProd("=============================================");
}
