import ContainerOuter from "components/common/ContainerOuter";
import ResponsiveContainer from "components/ResponsiveContainer";
import styles from "css/pages/shuffle/ShufflePage.module.css";
import Header1 from "components/text/Header1";
import Body1 from "components/text/Body1";
import ColorClass from "types/enums/ColorClass";
import ButtonWithText from "components/buttons/ButtonWithText";
import ButtonTheme from "types/enums/ButtonTheme";
import DS_STORE from "constants/DsStore";
import { Maybe } from "types/UtilityTypes";
import { useState } from "react";
import FolderGrid from "components/common/FolderGrid";
import LoadingSpinner from "components/loading/LoadingSpinner";
import ColorValue from "types/enums/ColorValue";
import JSZip from "jszip";
import randomNumber from "utils/randomNumber";
import invariant from "tiny-invariant";
import getFileExtension from "utils/getFileExtension";
import { saveAs } from "file-saver";
import TextButton from "components/buttons/TextButton";
import TextButtonTheme from "types/enums/TextButtonTheme";
import FontClass from "types/enums/FontClass";
import ElementMobileNotSupported from "components/hoc/ElementMobileNotSupported";
import ImageWebp from "components/images/ImageWebp";
import GateModal from "components/modal/GateModal";
import useLogPageView from "hooks/useLogPageView";
import useWhitelistContext from "hooks/useWhitelistContext";
import getCompareByProperty from "utils/getCompareByProperty";
import TextInput from "components/input/TextInput";
import emptyFunction from "utils/emptyFunction";

const ID = "input_id";

function getFileFolder(relPath: string, index: number): Maybe<string> {
  const splits = relPath.split("/");
  if (splits.length !== 3) {
    return null;
  }
  return splits[index];
}

/**
 * Updates the "name" attribute of a metadata file.
 *
 * The name attribute follows the pattern: "NAME #1", "NAME #2", etc.
 *
 * But since we are shuffling the order of metadata files, the number part
 * of the name must be updated.
 */
async function replaceNameInMetadata(file: File, num: number): Promise<string> {
  return new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.readAsText(file, "UTF-8");
    reader.onload = (e) => {
      const text = JSON.parse(e.target!.result as string);
      if (text.name != null) {
        text.name = text.name.replace(/#\d+/, `#${num}`);
      }
      if (text.image != null) {
        text.image = text.image.replace(/\d+/, `${num}`);
      }
      if (text.edition != null) {
        text.edition = Number(text.edition.toString().replace(/\d+/, `${num}`));
      }
      if (text.properties?.files != null) {
        text.properties.files = text.properties.files.map(
          (item: { uri: string; type: string }) =>
            item.type.includes("image")
              ? { ...item, uri: item.uri.replace(/\d+/, `${num}`) }
              : item
        );
      }
      resolve(JSON.stringify(text, null, 2));
    };
    reader.onerror = (e) => {
      reject(e);
    };
  });
}

type Files = Array<File>;
type Folder = {
  folderName: string;
  images: Files;
  metadata: Files;
};

function parseFiles(files: FileList): Folder {
  const filesArr = [...files].filter((file) => file.name !== DS_STORE);
  const folder: Folder = {
    folderName: "",
    images: [],
    metadata: [],
  };

  filesArr.forEach((file) => {
    // @ts-ignore
    const folderName = getFileFolder(file.webkitRelativePath, 1);
    // @ts-ignore
    const outerFolderName = getFileFolder(file.webkitRelativePath, 0);
    if (folderName == null || !["images", "metadata"].includes(folderName)) {
      throw new Error(
        "Invalid directory structure. Uploaded folder must only contain two folders named ‘images' and ‘metadata'."
      );
    }

    folder[folderName as "images" | "metadata"].push(file);
    folder.folderName = outerFolderName!;
  });

  if (folder.images.length !== folder.metadata.length) {
    throw new Error(
      "The images and metadata folders must contain the same number of files."
    );
  }

  return folder;
}

// TODO: should consolidate this with similar code in GeneratePage
function AddFolder({
  onAddFolder,
}: {
  onAddFolder: (val: Folder) => void;
}): JSX.Element {
  const [errorMessage, setErrorMessage] = useState<Maybe<string>>(null);
  const [isModalShown, setIsModalShown] = useState(false);
  const { isWhitelisted } = useWhitelistContext();

  return (
    <>
      <GateModal isShown={isModalShown} onHide={() => setIsModalShown(false)} />
      <div className={styles.addFolder}>
        <ImageWebp className={styles.addFolderImage} src="/images/folder.svg" />
        <Body1
          className={styles.addFolderDescription}
          colorClass={ColorClass.Navy}
        >
          Select which folders you want to shuffle. Please upload the folders
          that you downloaded from Nifty Generator (not your original art
          layers)!
        </Body1>
        <input
          className={styles.addFolderInput}
          id={ID}
          onChange={async (e) => {
            setErrorMessage(null);
            if (e.target.files == null) {
              return;
            }

            if (!isWhitelisted) {
              setIsModalShown(true);
              e.target.value = "";
              return;
            }

            try {
              const folder = parseFiles(e.target.files);
              onAddFolder(folder);
            } catch (err: any) {
              setErrorMessage(err.message);
            }
          }}
          type="file"
          // Directory stuff
          // @ts-ignore
          directory=""
          multiple
          webkitdirectory=""
        />
        <ButtonWithText
          buttonTheme={ButtonTheme.Purple}
          className={styles.addFolderButton}
          htmlFor={ID}
          type="label"
        >
          Add Folder
        </ButtonWithText>
        {errorMessage != null && (
          <Body1
            className={styles.errorMessage}
            colorClass={ColorClass.Error}
            textAlign="center"
          >
            {errorMessage}
          </Body1>
        )}
      </div>
    </>
  );
}

function FolderItem({ folder }: { folder: Folder }): JSX.Element {
  return (
    <div className={styles.folderItem}>
      <ImageWebp className={styles.folderImage} src="/images/folder.svg" />
      <Body1 textAlign="center">{folder.folderName}</Body1>
    </div>
  );
}

export default function ShufflePage(): JSX.Element {
  useLogPageView();
  const [folders, setFolders] = useState<Array<Folder>>([]);
  const [isDownloading, setIsDownloading] = useState(false);
  const [isMoreInfoShown, setIsMoreInfoShown] = useState(false);
  const [startingIndex, setStartingIndex] = useState("");

  return (
    <ElementMobileNotSupported>
      <ContainerOuter>
        <ResponsiveContainer>
          <div className={styles.containerInner}>
            <Header1 colorClass={ColorClass.Navy}>The Shuffle Tool</Header1>
            {/* TODO: tweak copy */}
            <Body1
              className={styles.description}
              colorClass={ColorClass.Navy}
              textAlign="center"
            >
              If your collection is made up of multiple Nifty Generator
              projects, you should shuffle the outputs.
              <br />
              <br />
              For example, if you’re making a collection of cat and dog NFTs,
              you may have used Nifty Generator to create a folder of cat images
              and a folder of dog images. Now you can use this Shuffle tool to
              mix them all up, and it will automatically rename the files and
              update the metadata.
              {isMoreInfoShown && (
                <>
                  <br />
                  <br />
                  Why is this necessary? Well, let&apos;s say you generated
                  images using two projects—cats and dogs—and you want to upload
                  all of the images to Candy Machine. You don&apos;t want to
                  upload all the cats first, and all the dogs next, because then
                  everyone who mints at the beginning will get a cat! So, in
                  order to randomize the order of all the NFTs, you can use this
                  tool.
                </>
              )}
            </Body1>
            <TextButton
              buttonTheme={TextButtonTheme.Navy}
              className={styles.moreInfoButton}
              fontClass={FontClass.Body2Bold}
              onClick={() => setIsMoreInfoShown((curr) => !curr)}
            >
              {isMoreInfoShown ? "Hide additional info" : "See more info"}
            </TextButton>
            <AddFolder
              onAddFolder={(val) => setFolders((curr) => [...curr, val])}
            />
            <FolderGrid className={styles.folderGrid}>
              {folders.map((folder, index) => (
                // eslint-disable-next-line react/no-array-index-key
                <FolderItem key={index} folder={folder} />
              ))}
            </FolderGrid>
            {folders.length > 0 && (
              <TextInput
                className={styles.textInput}
                onChange={setStartingIndex}
                placeholder="Starting index (defaults to 0)"
                value={startingIndex}
              />
            )}
            {folders.length > 0 && (
              <ButtonWithText
                buttonTheme={ButtonTheme.Purple}
                className={styles.shuffleButton}
                disabled={folders.length <= 1}
                onClick={async () => {
                  // OVERVIEW
                  //
                  // Do this in a loop, until there are no more files left:
                  // - Remove random image and metadata files
                  // - Rename image file
                  // - Rename metadata file
                  // - Modify metadata file's image name
                  // - Add modified files to new folders
                  //
                  // Then, download resulting folder

                  setIsDownloading(true);

                  const allImages = folders.reduce(
                    (acc: Files, currVal: Folder) => [
                      ...acc,
                      ...currVal.images.sort(getCompareByProperty("name")),
                    ],
                    []
                  );
                  const allMetadata = folders.reduce(
                    (acc: Files, currVal: Folder) => [
                      ...acc,
                      ...currVal.metadata.sort(getCompareByProperty("name")),
                    ],
                    []
                  );

                  invariant(
                    allImages.length === allMetadata.length,
                    "Must be same number of image files as metadata files"
                  );

                  const zip = new JSZip();
                  const imagesFolder = zip.folder("images");
                  const metadataFolder = zip.folder("metadata");
                  invariant(imagesFolder != null && metadataFolder != null);
                  let index =
                    startingIndex.length === 0 ? 0 : Number(startingIndex);

                  while (allImages.length > 0) {
                    const randomIndex = randomNumber(0, allImages.length - 1);

                    const [image] = allImages.splice(randomIndex, 1);
                    const [metadata] = allMetadata.splice(randomIndex, 1);
                    // eslint-disable-next-line no-await-in-loop
                    const metadataStr = await replaceNameInMetadata(
                      metadata,
                      index
                    );

                    imagesFolder.file(
                      `${index}.${getFileExtension(image)}`,
                      image
                    );
                    metadataFolder.file(`${index}.json`, metadataStr);

                    index++;
                  }

                  const generated = await zip.generateAsync({ type: "blob" });
                  saveAs(generated, "shuffled.zip");

                  setIsDownloading(false);
                }}
              >
                {isDownloading ? (
                  <LoadingSpinner
                    colorValue={ColorValue.White}
                    height={24}
                    width={24}
                  />
                ) : (
                  "Shuffle"
                )}
              </ButtonWithText>
            )}
          </div>
        </ResponsiveContainer>
      </ContainerOuter>
    </ElementMobileNotSupported>
  );
}
