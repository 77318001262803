import styles from "css/pages/how-it-works/HowItWorksPage.module.css";
import { useNavigate } from "react-router-dom";
import ContainerOuter from "components/common/ContainerOuter";
import ExplodedWithDescription from "components/pages/how-it-works/ExplodedWithDescription";
import HowItWorksCard from "components/pages/how-it-works/HowItWorksCard";
import ResponsiveContainer from "components/ResponsiveContainer";
import usePrefetch from "hooks/usePrefetch";
import ColorValue from "types/enums/ColorValue";
import useLogPageView from "hooks/useLogPageView";

function Card2({
  backOnClick,
  continueOnClick,
}: {
  backOnClick: () => void;
  continueOnClick: () => void;
}): JSX.Element {
  return (
    <HowItWorksCard
      backButton="Back"
      backOnClick={backOnClick}
      continueButton="Continue"
      continueOnClick={continueOnClick}
      description={
        <>
          Each layer in the image represents a trait (Hair, Outfit, etc), and
          each trait will have many variants (Short Purple Hair, Long Purple
          Hair, etc).
          <br />
          <br />
          Draw each variant on a transparent .PNG file so all the layers will be
          visible. Each image should be the same size.
          <br />
          <br />
          GIF files are also supported! If you use GIFs, make sure each GIF has
          the <b>same number of frames</b>.
        </>
      }
      title="Image Layering"
    >
      <ExplodedWithDescription theme={ColorValue.PurplePastel} />
    </HowItWorksCard>
  );
}

export default function HowItWorksPage2(): JSX.Element {
  useLogPageView();
  const navigate = useNavigate();
  usePrefetch("/how-it-works/1");
  usePrefetch("/how-it-works/3");

  return (
    <ContainerOuter>
      <ResponsiveContainer>
        <div className={styles.containerInner}>
          <Card2
            backOnClick={() => navigate("/how-it-works/1")}
            continueOnClick={() => navigate("/how-it-works/3")}
          />
        </div>
      </ResponsiveContainer>
    </ContainerOuter>
  );
}
