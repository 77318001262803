enum FontClass {
  Body1 = "body1Font",
  Body1Medium = "body1MediumFont",
  Body1SemiBold = "body1SemiBoldFont",
  Body1Bold = "body1BoldFont",
  Body2 = "body2Font",
  Body2Medium = "body2MediumFont",
  Body2SemiBold = "body2SemiBoldFont",
  Body2Bold = "body2BoldFont",
  ButtonText = "buttonTextFont",
  Header1 = "header1Font",
  Header3 = "header3Font",
  QuestionText = "questionTextFont",
}

export default FontClass;
