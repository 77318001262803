import DS_STORE from "constants/DsStore";
import path from "path";
import invariant from "tiny-invariant";
import isDependentFolder from "utils/isDependentFolder";

export default function filesToFilesMap(
  files: Array<File>,
  relativePaths?: Array<string>
): Map<string, Array<File>> {
  const filesMap: Map<string, Array<File>> = new Map();

  for (const [index, file] of files.entries()) {
    if (file.name === DS_STORE) {
      continue;
    }

    // @ts-ignore
    const relPath = file.webkitRelativePath;
    const relPathFallback = relativePaths == null ? null : relativePaths[index];
    const key = path
      .dirname(relPathFallback != null ? relPathFallback : relPath)
      .split("/")[1];
    invariant(key != null, `Invalid directory structure, key = ${key}`);

    const isDependent = isDependentFolder(key);
    if (isDependent.result) {
      continue;
    }

    if (filesMap.has(key)) {
      filesMap.set(
        key,
        [...filesMap.get(key)!, file].sort((a, b) =>
          a.name.localeCompare(b.name)
        )
      );
    } else {
      filesMap.set(key, [file]);
    }
  }

  return filesMap;
}
