import GenericModal from "components/modal/GenericModal";
import styles from "css/modal/PasteFromCsvModal.module.css";
import Body1 from "components/text/Body1";
import ColorClass from "types/enums/ColorClass";
import TextArea from "components/input/TextArea";
import { useState } from "react";
import ButtonWithText from "components/buttons/ButtonWithText";
import ButtonTheme from "types/enums/ButtonTheme";
import isNumber from "utils/isNumber";

type CsvLine = [string, number];

const PLACEHOLDER = `e.g.

Blue Hair, 10
Green Hair, 20
Red Hair, 5
`;

function isCsvLine(line: string): boolean {
  const splits = line.split(",").map((part) => part.trim());
  return splits.length === 2 && isNumber(splits[1]);
}

function isTsvLine(line: string): boolean {
  const splits = line.split("\t").map((part) => part.trim());
  return splits.length === 2 && isNumber(splits[1]);
}

function isLineValid(line: string): boolean {
  return isCsvLine(line) || isTsvLine(line);
}

function isContentValid(content: string): boolean {
  const lines = content.split("\n");
  return lines.every((line) => isLineValid(line));
}

function parseContent(content: string): Array<CsvLine> {
  const lines = content.split("\n");
  const linesParsed = lines.map((line) => {
    const separator = isCsvLine(line) ? "," : isTsvLine(line) ? "\t" : null;
    if (separator == null) {
      throw new Error("should not reach");
    }
    const splits: Array<string | number> = line
      .split(separator)
      .map((part) => part.trim());
    splits[1] = Number(splits[1]);
    return splits as CsvLine;
  });

  return linesParsed;
}

type Props = {
  isShown: boolean;
  onHide: () => void;
  onSubmit: (val: Array<CsvLine>) => void;
};

export default function PasteFromCsvModal({
  isShown,
  onHide,
  onSubmit,
}: Props): JSX.Element {
  const [content, setContent] = useState("");

  return (
    <GenericModal
      isShown={isShown}
      onHide={onHide}
      title="Paste from CSV or TSV"
    >
      <div className={styles.body}>
        <Body1
          className={styles.description}
          colorClass={ColorClass.Navy}
          textAlign="center"
        >
          Paste in comma-separated or tab-separated values (pasting from a
          spreadsheet should work).
        </Body1>
        <div className={styles.textArea}>
          <TextArea
            onChange={(val) => setContent(val)}
            placeholder={PLACEHOLDER}
            rows={20}
            value={content}
          />
        </div>
        <ButtonWithText
          buttonTheme={ButtonTheme.Purple}
          className={styles.button}
          disabled={!isContentValid(content)}
          onClick={() => {
            onSubmit(parseContent(content));
            onHide();
          }}
        >
          Use Values
        </ButtonWithText>
      </div>
    </GenericModal>
  );
}
