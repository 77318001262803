enum BackgroundColorClass {
  BackgroundOverlay = "backgroundColorBackgroundOverlay",
  Dimmer = "backgroundColorDimmer",
  Error = "backgroundColorError",
  Green = "backgroundColorGreen",
  Ghost = "backgroundColorGhost",
  Navy = "backgroundColorNavy",
  NavyHover = "backgroundColorNavyHover",
  Pink = "backgroundColorPink",
  Purple = "backgroundColorPurple",
  PurpleHover = "backgroundColorPurpleHover",
  PurplePastel = "backgroundColorPurplePastel",
  TransparentPartial = "backgroundColorTransparentPartial",
  White = "backgroundColorWhite",
}

export default BackgroundColorClass;
