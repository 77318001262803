import ButtonWithText from "components/buttons/ButtonWithText";
import ButtonTheme from "types/enums/ButtonTheme";

type Props = {
  className?: string;
  disabled?: boolean;
  href: string;
};

export default function BackButton({
  className,
  disabled = false,
  href,
}: Props): JSX.Element {
  return (
    <ButtonWithText
      buttonTheme={ButtonTheme.Purple}
      className={className}
      disabled={disabled}
      href={href}
      type="link_internal"
      width="auto"
    >
      Back
    </ButtonWithText>
  );
}
