import ResponsiveContainer from "components/ResponsiveContainer";
import styles from "css/pages/generate/GeneratePage.module.css";
import Body1 from "components/text/Body1";
import ButtonWithText from "components/buttons/ButtonWithText";
import ButtonTheme from "types/enums/ButtonTheme";
import ContainerOuter from "components/common/ContainerOuter";
import useGenerateConfigContext from "hooks/useGenerateConfigContext";
import ProjectGrid from "components/pages/generate/ProjectGrid";
import ColorClass from "types/enums/ColorClass";
import { useNavigate } from "react-router-dom";
import ElementMobileNotSupported from "components/hoc/ElementMobileNotSupported";
import ImageWebp from "components/images/ImageWebp";
import GateModal from "components/modal/GateModal";
import { useState } from "react";
import useLogPageView from "hooks/useLogPageView";
import useWhitelistContext from "hooks/useWhitelistContext";

const ID = "input_id";

function ChooseFolder(): JSX.Element {
  const { createNewProject } = useGenerateConfigContext();
  const { isWhitelisted } = useWhitelistContext();
  const navigate = useNavigate();
  const [isModalShown, setIsModalShown] = useState(false);

  return (
    <>
      <GateModal isShown={isModalShown} onHide={() => setIsModalShown(false)} />
      <div className={styles.chooseFolder}>
        <ImageWebp
          className={styles.chooseFolderImage}
          src="/images/folder.svg"
        />
        <Body1
          className={styles.chooseFolderDescription}
          colorClass={ColorClass.Navy}
        >
          Start a new project by connecting your main folder.
          <br />
          <br />
          Images (.png files) and gifs (.gif files) are supported.
        </Body1>
        <input
          className={styles.chooseFolderInput}
          id={ID}
          onChange={async (e) => {
            if (e.target.files == null) {
              return;
            }

            if (
              [...e.target.files].find((file) => file.type.includes("gif")) !=
                null &&
              !isWhitelisted
            ) {
              setIsModalShown(true);
              // Allow upload again
              e.target.value = "";
              return;
            }

            await createNewProject(e.target.files);
            navigate("/generate/basic-info");
          }}
          type="file"
          // Directory stuff
          // @ts-ignore
          directory=""
          multiple
          webkitdirectory=""
        />
        <ButtonWithText
          buttonTheme={ButtonTheme.Purple}
          className={styles.chooseFolderButton}
          htmlFor={ID}
          type="label"
        >
          Choose Folder
        </ButtonWithText>
      </div>
    </>
  );
}

export default function GeneratePage(): JSX.Element {
  useLogPageView();
  return (
    <ElementMobileNotSupported>
      <ContainerOuter>
        <ResponsiveContainer>
          <div className={styles.containerInner}>
            <ChooseFolder />
            <ProjectGrid className={styles.projectGrid} />
          </div>
        </ResponsiveContainer>
      </ContainerOuter>
    </ElementMobileNotSupported>
  );
}
