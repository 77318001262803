import GenericModal from "components/modal/GenericModal";
import styles from "css/modal/ImageModal.module.css";
import joinClasses from "utils/joinClasses";
import GlobalClass from "types/enums/GlobalClass";
import useGenerateConfigContext from "hooks/useGenerateConfigContext";
import isPixelArt from "utils/isPixelArt";

type Props = {
  isShown: boolean;
  onHide: () => void;
  src: string;
};

export default function ImageModal({
  isShown,
  onHide,
  src,
}: Props): JSX.Element {
  const { fileDimensions } = useGenerateConfigContext();

  return (
    <GenericModal
      className={joinClasses(
        styles.modal,
        GlobalClass.HideText,
        isPixelArt(fileDimensions) ? GlobalClass.PixelArtImage : null
      )}
      isShown={isShown}
      onHide={onHide}
    >
      <img className={styles.image} src={src} />
    </GenericModal>
  );
}
