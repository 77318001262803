enum ColorClass {
  BackgroundOverlay = "colorBackgroundOverlay",
  Dimmer = "colorDimmer",
  Error = "colorError",
  Green = "colorGreen",
  Ghost = "colorGhost",
  Navy = "colorNavy",
  NavyHover = "colorNavyHover",
  Pink = "colorPink",
  Purple = "colorPurple",
  PurpleHover = "colorPurpleHover",
  PurplePastel = "colorPurplePastel",
  TransparentPartial = "colorTransparentPartial",
  White = "colorWhite",
}

export default ColorClass;
