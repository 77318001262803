import styles from "css/pages/generate/ProjectGrid.module.css";
import PlainButton from "components/buttons/PlainButton";
import FolderGrid from "components/common/FolderGrid";
import Body1 from "components/text/Body1";
import ButtonText from "components/text/ButtonText";
import useGenerateConfigContext from "hooks/useGenerateConfigContext";
import useProjectIdsAndNames from "hooks/useProjectIdsAndNames";
import ColorClass from "types/enums/ColorClass";
import { useNavigate } from "react-router-dom";
import ImageWebp from "components/images/ImageWebp";

function Item({
  projectId,
  projectName,
}: {
  projectId: string;
  projectName: string;
}): JSX.Element {
  const navigate = useNavigate();
  const { switchProject } = useGenerateConfigContext();

  return (
    <PlainButton
      className={styles.item}
      onClick={async () => {
        await switchProject(projectId);
        navigate("/generate/basic-info");
      }}
    >
      <ImageWebp className={styles.folderImage} src="/images/folder.svg" />
      <ButtonText colorClass={ColorClass.Navy} textAlign="center">
        {projectName}
      </ButtonText>
    </PlainButton>
  );
}

function Grid(): JSX.Element {
  const { projectIdsAndNames } = useProjectIdsAndNames();

  return (
    <FolderGrid className={styles.folderGrid}>
      {projectIdsAndNames.map((idAndName: any) => (
        <Item
          key={idAndName.id}
          projectId={idAndName.id}
          projectName={idAndName.name}
        />
      ))}
    </FolderGrid>
  );
}

type Props = {
  className?: string;
};

export default function ProjectGrid({ className }: Props): JSX.Element {
  return (
    <div className={className}>
      <Body1 colorClass={ColorClass.Navy} textAlign="center">
        or, hop into a previous project:
      </Body1>
      <Grid />
    </div>
  );
}
