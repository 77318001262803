import { useWalletDialog } from "@solana/wallet-adapter-material-ui";
import { useAnchorWallet } from "@solana/wallet-adapter-react";
import ButtonWithText from "components/buttons/ButtonWithText";
import ButtonTheme from "types/enums/ButtonTheme";
import FontClass from "types/enums/FontClass";
import useWhitelistContext from "hooks/useWhitelistContext";

export default function ConnectWalletButton(): JSX.Element {
  const wallet = useAnchorWallet();
  const { setOpen } = useWalletDialog();
  const { isChecking } = useWhitelistContext();

  if (wallet != null) {
    return (
      <ButtonWithText
        buttonTheme={ButtonTheme.Purple}
        fontClass={FontClass.ButtonText}
        // @ts-ignore
        onClick={() => window.solana.disconnect()}
      >
        {isChecking ? `Loading...` : `Disconnect`}
      </ButtonWithText>
    );
  }

  return (
    <ButtonWithText
      buttonTheme={ButtonTheme.Purple}
      fontClass={FontClass.ButtonText}
      onClick={() => setOpen(true)}
    >
      Connect wallet
    </ButtonWithText>
  );
}
