export default function formatPercentage(num: number): string {
  const formatted = num.toFixed(2);
  const split = formatted.split(".");
  if (split[1] === "00") {
    return split[0];
  }
  if (split[1][1] === "0") {
    return formatted.slice(0, -1);
  }
  return formatted;
}
