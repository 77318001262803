import styles from "css/pages/how-it-works/HowItWorksCard.module.css";
import ButtonWithText from "components/buttons/ButtonWithText";
import Body1 from "components/text/Body1";
import Header1 from "components/text/Header1";
import ButtonTheme from "types/enums/ButtonTheme";
import ColorClass from "types/enums/ColorClass";

type Props = {
  backButton?: string;
  backOnClick?: () => void;
  continueButton?: string;
  continueOnClick?: () => void;
  children: any;
  description: string | JSX.Element;
  title: string;
};

export default function HowItWorksCard({
  backButton,
  backOnClick,
  continueButton,
  continueOnClick,
  children,
  description,
  title,
}: Props): JSX.Element {
  return (
    <div className={styles.container}>
      <Header1 colorClass={ColorClass.Navy} textAlign="center">
        {title}
      </Header1>
      <Body1
        className={styles.description}
        colorClass={ColorClass.Navy}
        textAlign="center"
      >
        {description}
      </Body1>
      <div className={styles.children}>{children}</div>
      <div className={styles.buttons}>
        {backButton != null && (
          <ButtonWithText
            buttonTheme={ButtonTheme.Purple}
            className={styles.button}
            onClick={backOnClick}
          >
            {backButton}
          </ButtonWithText>
        )}
        {continueButton != null && (
          <ButtonWithText
            buttonTheme={ButtonTheme.Purple}
            className={styles.button}
            onClick={continueOnClick}
          >
            {continueButton}
          </ButtonWithText>
        )}
      </div>
    </div>
  );
}
