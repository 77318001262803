import { getMany } from "idb-keyval";
import LocalStorageKey from "types/enums/LocalStorageKey";
import getLocalStorage from "utils/local-storage/getLocalStorage";
import prefixKeyWithProjectId from "utils/local-storage/prefixKeyWithProjectId";

export default async function getManyLocalStorage(
  keys: Array<LocalStorageKey>
): Promise<Array<any>> {
  const projectId = await getLocalStorage(LocalStorageKey.ProjectId);
  if (projectId == null) {
    return keys.map((_) => null);
  }

  const prefixedKeys = await Promise.all(
    keys.map((key) => {
      if (
        [LocalStorageKey.AllProjectIds, LocalStorageKey.ProjectId].includes(key)
      ) {
        return key;
      }
      return prefixKeyWithProjectId(key, projectId);
    })
  );

  return getMany(prefixedKeys);
}
