import styles from "css/hoc/ElementMobileNotSupported.module.css";
import ContainerOuter from "components/common/ContainerOuter";
import ResponsiveContainer from "components/ResponsiveContainer";
import Body1 from "components/text/Body1";
import ColorClass from "types/enums/ColorClass";

type Props = {
  children: any;
};

export default function ElementMobileNotSupported({
  children,
}: Props): JSX.Element {
  return (
    <>
      <div className={styles.mobileContainer}>
        <ContainerOuter>
          <ResponsiveContainer>
            <Body1 colorClass={ColorClass.White} textAlign="center">
              NFT generation is not supported on mobile 😢 Please try on a
              desktop computer.
            </Body1>
          </ResponsiveContainer>
        </ContainerOuter>
      </div>
      <div className={styles.desktopContainer}>{children}</div>
    </>
  );
}
