import ContainerOuter from "components/common/ContainerOuter";
import GenerateStepsContainer from "components/common/GenerateStepsContainer";
import useGenerateConfigContext from "hooks/useGenerateConfigContext";
import { Maybe } from "types/UtilityTypes";
import AllocateSingleTrait from "components/pages/generate/trait-allocation/AllocateSingleTrait";
import NextAndBackButton from "components/buttons/NextAndBackButton";
import NextButton from "components/buttons/NextButton";
import BackButton from "components/buttons/BackButton";
import ElementMobileNotSupported from "components/hoc/ElementMobileNotSupported";
import useLogPageView from "hooks/useLogPageView";

export default function TraitAllocationPage(): Maybe<JSX.Element> {
  useLogPageView();
  const { traitNamesOrdered } = useGenerateConfigContext();

  if (traitNamesOrdered == null) {
    return null;
  }

  return (
    <ElementMobileNotSupported>
      <ContainerOuter>
        <GenerateStepsContainer>
          {traitNamesOrdered?.map((traitName) => (
            <AllocateSingleTrait key={traitName} traitName={traitName} />
          ))}
          <NextAndBackButton>
            <BackButton href="/generate/layer-ordering" />
            <NextButton href="/generate/generation" />
          </NextAndBackButton>
        </GenerateStepsContainer>
      </ContainerOuter>
    </ElementMobileNotSupported>
  );
}
