import dataUriToBuffer from "data-uri-to-buffer";

export default function getExampleProjectFiles() {
  return [
    // BACKGROUNDS
    {
      file: new File(
        [
          dataUriToBuffer(
            "data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAACAAAAAgCAYAAABzenr0AAAAAXNSR0IArs4c6QAAAG9JREFUWIVj3Pvp/3+GAQRMA2n5qANGHTDqgFEHjDpg1AGjDhh1wOBwwLePA2f5t4+DIQRgLqE3gNk5OEKAgYG+oYBsFxMuCXpYjuEAWjsCm9lY0wAtHIHLTBZCGrj4aWMxQQcQawClYPBkwxHrAAC+4CCThpiIIQAAAABJRU5ErkJggg==)"
          ),
        ],
        "Blue Background"
      ),
      relPath: "images/Background/Blue Background.png",
    },
    {
      file: new File(
        [
          dataUriToBuffer(
            "data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAACAAAAAgCAYAAABzenr0AAAAAXNSR0IArs4c6QAAAHJJREFUWIVjvPT/wX+GAQRMA2n5qANGHTDqgFEHjDpg1AGjDhh1wOBwwI0fvwbM8hs/fg2CEIC5hN4AZufgCAEGBvqGArJdTLgk6GE5hgNo7QhsZmNNA7RwBC4zWQhp0OBgo4nFBB1ArAGUgsGTDUesAwB1dS4Awm9kRAAAAABJRU5ErkJggg=="
          ),
        ],
        "Green Background"
      ),
      relPath: "images/Background/Green Background.png",
    },
    {
      file: new File(
        [
          dataUriToBuffer(
            "data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAACAAAAAgCAYAAABzenr0AAAAAXNSR0IArs4c6QAAAHBJREFUWIXt0zEKACEMBMBVrrC7/3/J39jZaWMhnKJwxATctGJ2WNSVGAsUx2uGE0AAAQQQQAABBBgBpKyXnrKFBgCotNAyjTQAnG2hy/KzgxPhX4A0YrB7/AYkEJOdz/LCG0SC14DNBX/H0De8FVABXgwglGMtZZUAAAAASUVORK5CYII="
          ),
        ],
        "Pink Background"
      ),
      relPath: "images/Background/Pink Background.png",
    },

    // Faces
    {
      file: new File(
        [
          dataUriToBuffer(
            "data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAACAAAAAgCAYAAABzenr0AAAAAXNSR0IArs4c6QAAAQhJREFUWIVjYBgFo2AUjHTASIwiFRWV/zD2nTt38OohRS1RDlBRUfnfeKgEzq+368FpMClqiXIAzMAHnxFiCrzYDSZFLTJgwecAZDDfu4eBgYGBIXFrCUPjoRKGerue/8jyMMuR1REDcLoMm4/IAYRCgYky4ykHeBMTzPdZByHunGb/D64GFtQwgBzk6OrxhQLRaQAdEBvHhABOByDHPbLPiQHo6vGlowFPA6MOwOmA8+9/UM0SfGYNeAjgLQkNluQwGApyUGTB+fc/GC7ETBliJSEMwEKBgYGB5JCAxTs+3xN0AMwRDAwMDDCHEAsuxExhYGAg3CghqkWE7BBiATGtoVEwCgYFAADLHH3v/EpT2wAAAABJRU5ErkJggg=="
          ),
        ],
        "Sleepy"
      ),
      relPath: "images/Face/Sleepy.png",
    },
    {
      file: new File(
        [
          dataUriToBuffer(
            "data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAACAAAAAgCAYAAABzenr0AAAAAXNSR0IArs4c6QAAARhJREFUWIXtlr0NwjAQhR+IgoaCDSg8QFggDMAKNLQoZfoofcqINg0rMAAsAAOkYIMUNKkIBTqwjH/OAgQS/ionudy9dzk5BgKBwL/T4wQJITpa13VtfccnliVACNHl+/R+ncWFMbFPLEsAJTydH/cmI31in1iZgU2ATDUvAADLbYp8nyKLi05+TsXlOA5GZaqjal6wkqpxri70WTLx7Iicqmuuc8I6TOR+tbvpXM8uWhFqUTXe1gX2DOjwdavDKECeZtW5CzVezqXCnoFPEQQYBRya9m1FbLm+3gHrThhtEkzHw5cKHJoWx0X5+k74KZx/w2iTAIB3J+i729w7BZAIACAhXI6LEoD7UMI6EclCuHBOQ4HAT3AFu9mJ726/uAYAAAAASUVORK5CYII="
          ),
        ],
        "Smile"
      ),
      relPath: "images/Face/Smile.png",
    },
    {
      file: new File(
        [
          dataUriToBuffer(
            "data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAACAAAAAgCAYAAABzenr0AAAAAXNSR0IArs4c6QAAAS9JREFUWIXtljFuwkAQRV8QRZoUuQHFHiApaMkBuAINLaK0aBGt5RLRpskVOEBoKeAALrgBBQ1VnGpggd31rJIIS9lXre3vmT8zK3shkUj8dx40ImNMJeuyLIPvxGhVBowx1WyVna6nvcIbOEarMiABd4fzvc6TO3CM1qYdMmDz3i8AGC4zZquMaa+o7OeS3NZp8BpwVSTsDrcJfDox6+uCugOuilzVaisXgptJqhp9tgBYvH1dJF9PJgB08/wi8bU+tBdaUXb/AO8I7JnalQvDZUa3n5/WNtd61/6oNaAhdt4u7j6C5hrY7I+/liQU6+4d8H6jjTHVy8eY1+fHHyXY7I9sB3Pv/6C5HYBzF4DoTsjcQ9XXGhATAGJEy3YwB+oPJaoTkW1Ei+Y0lEg0gm8KDZSydI6BIQAAAABJRU5ErkJggg=="
          ),
        ],
        "Surprised"
      ),
      relPath: "images/Face/Surprised.png",
    },

    // Outfit
    {
      file: new File(
        [
          dataUriToBuffer(
            "data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAACAAAAAgCAYAAABzenr0AAAAAXNSR0IArs4c6QAAAKtJREFUWIXt0DEOgkAQBdAvWHsA6jHhNHsTQreeARtCLGk8gw1XWSI1B7AGx0KXRGIxlCb/Vbuzk/mTBYiIiIiIiOifiIhObadT26mIaN1fte6vuq5vmbnbEn5vmuU+jwnyqgAAhNMFafZc3o5liWEYTLMT86qfwVFeFQjOIzi/LLLusTBtKSIanH8H384AgOA80uwBAJjHw1c99ll+YW/dNIbFgHiP5191ixeqSkTlBtP15AAAAABJRU5ErkJggg=="
          ),
        ],
        "Collared Shirt"
      ),
      relPath: "images/Outfit/Collared Shirt.png",
    },
    {
      file: new File(
        [
          dataUriToBuffer(
            "data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAACAAAAAgCAYAAABzenr0AAAAAXNSR0IArs4c6QAAAKxJREFUWIXt0DEKwjAUxvG/4uDY2TkBETK4eYMWnD2FVxAJ4hU8RWeh3sDNoRCEdHbO6Fa3UjvI6+TyfluSj5cvAaWUUkoppZRSfzYZEzbGtHWRAeCqxP56AOCyPdPfb5pGPFccNMa00a+69fv+wlUJgLrImG8W3Zn1QVxiJi0w5KrE87QGYHl8EHsFxhC1HL7e+kAs86+M3d0YZiS/MBVX/XE5QCxzrA9jx/EBFygznC7M2g8AAAAASUVORK5CYII="
          ),
        ],
        "Cape Shirt"
      ),
      relPath: "images/Outfit/Cape Shirt.png",
    },
    {
      file: new File(
        [
          dataUriToBuffer(
            "data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAACAAAAAgCAYAAABzenr0AAAAAXNSR0IArs4c6QAAAJdJREFUWIVjYBgFo2AUjIJRMApGwSgYBaNgFIyCUTDAgJEUxSoqKv8tMk4zMDAwMJyYYcqQvbWGgYGBgWGqdwsDsvidO3dIMpdoy0/d/g/Hi9d9/6+iovJfRUXl/+J131HkVFRU/hNrLgspjthw6SsKH+br6///MlxHkyMWEBVUKioq/5dtv02SwVGeqkRFBdEhgO57agEAT21B4c/1CnwAAAAASUVORK5CYII="
          ),
        ],
        "Striped Shirt"
      ),
      relPath: "images/Outfit/Striped Shirt.png",
    },
  ];
}
