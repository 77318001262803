enum ColorValue {
  BackgroundOverlay = "rgba(0, 0, 0, 0.3)",
  Dimmer = "rgba(0, 0, 0, 0.36)",
  Error = "#e92525",
  Green = "#0d9350",
  Ghost = "#c6c6c6",
  Navy = "#172A70",
  NavyHover = "#080561",
  Pink = "#FFCBCB",
  Purple = "#506CD0",
  PurpleHover = "#4460c5",
  PurplePastel = "#A1B0EA",
  TransparentPartial = "rgba(255, 255, 255, 0.8)",
  White = "#FFFFF3",
}

export default ColorValue;
