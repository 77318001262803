import styles from "css/pages/generate/trait-allocation/AllocateSingleTrait.module.css";
import { useState } from "react";
import ButtonWithText from "components/buttons/ButtonWithText";
import InputWithLeftLabel from "components/input/InputWithLeftLabel";
import InputWithQuestionAndLabel from "components/input/InputWithQuestionAndLabel";
import TextInput from "components/input/TextInput";
import PasteFromCsvModal from "components/modal/PasteFromCsvModal";
import Body1 from "components/text/Body1";
import Body2 from "components/text/Body2";
import useGenerateConfigContext from "hooks/useGenerateConfigContext";
import ButtonTheme from "types/enums/ButtonTheme";
import ColorClass from "types/enums/ColorClass";
import FontClass from "types/enums/FontClass";
import { Maybe } from "types/UtilityTypes";
import formatPercentage from "utils/formatPercentage";
import isNumber from "utils/isNumber";
import numberRemoveCommas from "utils/numberRemoveCommas";
import numberWithCommas from "utils/numberWithCommas";
import removeExt from "utils/removeExt";
import sumArray from "utils/sumArray";

function TraitInput({
  traitName,
  traitValue,
}: {
  traitName: string;
  traitValue: string;
}): JSX.Element {
  const { dispatchTraitWeights, numImages, traitWeights } =
    useGenerateConfigContext();
  const weight = traitWeights.get(traitName)?.get(traitValue) ?? 0;

  return (
    <>
      <InputWithLeftLabel label={removeExt(traitValue)}>
        <TextInput
          className={styles.input}
          onChange={(val) => {
            const valCommasRemoved = numberRemoveCommas(val);
            if (!isNumber(valCommasRemoved)) {
              return;
            }
            dispatchTraitWeights({
              type: "update_for_value",
              traitName,
              traitValue,
              weight: Number(valCommasRemoved),
            });
          }}
          value={numberWithCommas(weight)}
        />
      </InputWithLeftLabel>
      <Body2 className={styles.percent} colorClass={ColorClass.Purple}>
        {formatPercentage((weight * 100) / numImages)}%
      </Body2>
    </>
  );
}

type Props = {
  traitName: string;
};

export default function AllocateSingleTrait({
  traitName,
}: Props): Maybe<JSX.Element> {
  const { dispatchTraitWeights, filesMap, numImages, traitWeights } =
    useGenerateConfigContext();
  const traitMap = traitWeights.get(traitName);
  const totalAllocated =
    traitMap == null ? 0 : sumArray([...traitMap.values()]);
  const numLeftToAllocate = numImages - totalAllocated;
  const [isCsvModalShown, setIsCsvModalShown] = useState(false);

  if (filesMap == null) {
    return null;
  }

  return (
    <>
      <PasteFromCsvModal
        isShown={isCsvModalShown}
        onHide={() => setIsCsvModalShown(false)}
        onSubmit={(lines) => {
          dispatchTraitWeights({
            type: "update_for_values",
            traitName,
            values: lines.map((line) => ({
              traitValue: line[0],
              weight: line[1],
            })),
          });
        }}
      />
      <InputWithQuestionAndLabel
        label={`This must add up to ${numberWithCommas(
          numImages
        )}, the total number of images you’re generating.`}
        question={
          <>
            For the trait &ldquo;{traitName},&rdquo; how many of each variant
            should be used?
          </>
        }
      >
        <Body1
          colorClass={
            numLeftToAllocate < 0
              ? ColorClass.Error
              : numLeftToAllocate === 0
              ? ColorClass.Green
              : ColorClass.Navy
          }
          textAlign="center"
        >
          Number left to allocate: {numberWithCommas(numLeftToAllocate)}
        </Body1>
        <div className={styles.buttons}>
          <ButtonWithText
            buttonTheme={ButtonTheme.Purple}
            className={styles.button}
            fontClass={FontClass.Body2}
            onClick={() =>
              dispatchTraitWeights({
                type: "allocate_evenly",
                numTotal: numImages,
                traitName,
                traitValues: [
                  ...filesMap.get(traitName)!.map((file) => file.name),
                ],
              })
            }
          >
            Allocate Evenly
          </ButtonWithText>
          <ButtonWithText
            buttonTheme={ButtonTheme.Purple}
            className={styles.button}
            fontClass={FontClass.Body2}
            onClick={() => setIsCsvModalShown(true)}
          >
            Paste from CSV or TSV
          </ButtonWithText>
        </div>
        <div className={styles.inputs}>
          {filesMap.get(traitName)!.map((file) => (
            <TraitInput
              key={file.name}
              traitName={traitName}
              traitValue={file.name}
            />
          ))}
        </div>
      </InputWithQuestionAndLabel>
    </>
  );
}
