import DATA_URI_GIF_PREFIX from "constants/DataUriGifPrefix";
import DATA_URI_PNG_PREFIX from "constants/DataUriPngPrefix";
import isDataUriGif from "utils/isDataUriGif";

export default function getDataFromDataUri(dataUri: string): string {
  if (isDataUriGif(dataUri)) {
    return dataUri.replace(DATA_URI_GIF_PREFIX, "");
  }
  return dataUri.replace(DATA_URI_PNG_PREFIX, "");
}
