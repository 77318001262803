enum LocalStorageKey {
  AllProjectIds = "all_project_ids",
  Blockchain = "blockchain",
  Files = "files",
  FileRelativePaths = "file_relative_paths",
  GenerateMetadata = "generate_metadata",
  MetaCollectionFamily = "meta_collection_family",
  MetaCollectionName = "meta_collection_name",
  MetaDescription = "meta_description",
  MetaExternalUrl = "meta_external_url",
  MetaName = "meta_name",
  MetaRoyalties = "meta_royalties",
  MetaSellerFeeBasisPoints = "meta_seller_fee_basis_points",
  MetaSymbol = "meta_symbol",
  NumImages = "num_images",
  ProjectId = "project_id",
  ProjectName = "project_name",
  TraitNamesOrdered = "trait_names_ordered",
  TraitWeights = "trait_weights",
}

export default LocalStorageKey;
