import "css/global/Global.css";

// Colors
import "css/global/colors/ColorVariables.css";
import "css/global/colors/BackgroundColorClasses.css";
import "css/global/colors/ColorClasses.css";

// Fonts
import "css/global/fonts/FontClasses.css";
import "css/global/fonts/FontVariables.css";
import "css/global/fonts/Praline.css";

import Routes from "routes/Routes";
import { BrowserRouter } from "react-router-dom";
import LandingBanner from "components/pages/landing/LandingBanner";
import { GenerateConfigContextProvider } from "context/GenerateConfigContext";
import { SolanaContextProvider } from "context/SolanaContext";
import "utils/firebase/firebaseApp";

function App() {
  return (
    <SolanaContextProvider>
      <GenerateConfigContextProvider>
        <BrowserRouter>
          <LandingBanner />
          <Routes />
        </BrowserRouter>
      </GenerateConfigContextProvider>
    </SolanaContextProvider>
  );
}

export default App;
