import styles from "css/pages/how-it-works/ExplodedWithDescription.module.css";
import joinClasses from "utils/joinClasses";
import ColorValue from "types/enums/ColorValue";
import Header3 from "components/text/Header3";
import ColorClass from "types/enums/ColorClass";
import Body1 from "components/text/Body1";
import ImageWebp from "components/images/ImageWebp";

const DESCRIPTIONS = [
  {
    description:
      "Short Purple Hair, Long Purple Hair, Short Green Hair, Long Green Hair",
    title: "Hair Layer",
  },
  {
    description: "Fanny Pack Shirt, Basic Shirt, Fancy Shirt",
    title: "Outfit Layer",
  },
  {
    description: "Shy, Happy, Sad",
    title: "Expression Layer",
  },
  {
    description: "Brown, Green, Purple",
    title: "Body Layer",
  },
  {
    description: "Cat, Dog",
    title: "Pet Layer",
  },
];

function DescriptionItem({
  description,
  title,
}: {
  description: string;
  title: string;
}): JSX.Element {
  return (
    <div className={styles.item}>
      <Header3 colorClass={ColorClass.Navy} textTransform="uppercase">
        {title}
      </Header3>
      <Body1 colorClass={ColorClass.Navy}>{description}</Body1>
    </div>
  );
}

type Props = {
  className?: string;
  theme?: ColorValue.White | ColorValue.PurplePastel;
};

export default function ExplodedWithDescription({
  className,
  theme = ColorValue.White,
}: Props): JSX.Element {
  const exploded = (
    <ImageWebp
      className={styles.exploded}
      src={
        theme === ColorValue.White
          ? "/images/exploded-white.png"
          : "/images/exploded-purple.png"
      }
    />
  );

  const descriptions = (
    <div className={styles.descriptions}>
      {DESCRIPTIONS.map(({ description, title }) => (
        <DescriptionItem key={title} description={description} title={title} />
      ))}
    </div>
  );

  return (
    <div className={joinClasses(styles.container, className)}>
      {exploded}
      {descriptions}
    </div>
  );
}
