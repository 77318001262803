import {
  doc,
  updateDoc,
  getFirestore,
  setDoc,
  getDoc,
} from "firebase/firestore";

export default async function setWhitelist(
  address: string,
  isWhitelist: boolean
): Promise<void> {
  const db = getFirestore();
  const docRef = doc(db, "users", address);
  const docSnap = await getDoc(docRef);

  if (!docSnap.exists()) {
    await setDoc(docRef, {
      address,
    });
  }

  await updateDoc(docRef, {
    whitelist: isWhitelist,
  });
}
