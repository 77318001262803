import styles from "css/pages/landing/LandingInfo.module.css";
import TextButton from "components/buttons/TextButton";
import ResponsiveContainer from "components/ResponsiveContainer";
import Body1 from "components/text/Body1";
import Header1 from "components/text/Header1";
import BackgroundColorClass from "types/enums/BackgroundColorClass";
import ColorClass from "types/enums/ColorClass";
import FontClass from "types/enums/FontClass";
import TextButtonTheme from "types/enums/TextButtonTheme";

function Link({
  children,
  href,
}: {
  children: any;
  href: string;
}): JSX.Element {
  return (
    <TextButton
      buttonTheme={TextButtonTheme.Navy}
      display="inline"
      fontClass={FontClass.Body1Bold}
      href={href}
      type="link_external"
    >
      {children}
    </TextButton>
  );
}

export default function LandingInfo(): JSX.Element {
  return (
    <div className={BackgroundColorClass.Pink}>
      <ResponsiveContainer>
        <div className={styles.containerInner}>
          <Header1 colorClass={ColorClass.Navy} textAlign="center">
            From our NFT project, to yours
          </Header1>
          <Body1
            className={styles.description}
            colorClass={ColorClass.Navy}
            textAlign="center"
          >
            Hi, <Link href="https://twitter.com/petrichorate">Kat</Link> &{" "}
            <Link href="https://twitter.com/pencilflip">Matt</Link> here! We’re
            builders and creatives, and we made Nifty Generator to help more
            artists start making generative NFTs without having to touch code.
            <br />
            <br />
            We’re launching our own NFT project called the{" "}
            <Link href="https://mycoverse.xyz/">Mycoverse</Link>—our mission is
            to onboard the next 1 million artists into the metaverse. The first
            step is turning our code from the Mycoverse into this free tool for
            creators! And we’re going to keep expanding on Nifty Generator and
            making more awesome tools—because ultimately, our goal is to make it
            easy for more creators to bring their talents into the metaverse.
            <br />
            <br />
            You can see some other web3 projects we’ve built for artists and
            creators here:{" "}
            <Link href="https://niftypixels.io/">Nifty Pixels</Link>,{" "}
            <Link href="https://niftyairdrop.xyz/">Nifty Airdrop</Link>.
          </Body1>
        </div>
      </ResponsiveContainer>
    </div>
  );
}
